import React from "react";

import { ReactComponent as SvgYandex } from "../../assets/payment/yandex.svg";
import { ReactComponent as SvgCashDelivery } from "../../assets/payment/cash-on-delivery.svg";
import { ReactComponent as SvgQiwi } from "../../assets/payment/qiwi.svg";
import { ReactComponent as SvgSber } from "../../assets/payment/sber.svg";

import pageBg from "../../assets/banners/title-delivery.jpg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiPageTitle from "../../components/header/UiPageTitle";

import "./Static.scss";

function DeliveryPage() {
  return (
    <PageContainer page="delivery" pageTitle="">
      <UiPageTitle
        image={pageBg}
        large={true}
        linksList={[{ href: "/delivery", label: "Оплата и доставка" }]}
        pageName="Информация об оплате и доставке"
      />
      <Container>
        <div className="delivery-wrap">
          <div className="delivery-info-list">
            <div className="delivery-info-payment">
              <h3>Методы оплаты</h3>
              <p>
                Оплата принимается с предоплатой 100% от стоимости заказа любым
                удобным для Вас способом:
              </p>
              <div className="delivery-info-payment-list">
                <div className="delivery-info-payment-item">
                  <div
                    className="delivery-info-payment-item-info-image-wrap"
                    style={{ backgroundColor: " #21a049" }}
                  >
                    <SvgSber className="delivery-info-payment-item-image" />
                  </div>
                  <div className="delivery-info-payment-item-info">
                    <h4>Сбербанк</h4>
                    <p>
                      Перевод предоплаты по номеру карты. Номер карты: 000000000
                    </p>
                  </div>
                </div>

                <div className="delivery-info-payment-item">
                  <div
                    className="delivery-info-payment-item-info-image-wrap"
                    style={{ backgroundColor: " #21a049" }}
                  >
                    <SvgSber className="delivery-info-payment-item-image" />
                  </div>
                  <div className="delivery-info-payment-item-info">
                    <h4>Онлайн</h4>
                    <p>
                      Безопасная оплата на нашем сайте при оформлении заказа
                      онлайн
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="delivery-info-payment">
              <h3>Круглосуточная доставка</h3>

              <p>Днем с 8:00 до 22:00</p>
              <p>Доставка по городу 270р.</p>
              <p>
                Осуществляется в течении 2 часов с момента оформления заказа.
              </p>

              <p>Ночью с 22:00 до 8:00</p>
              <p>Доставка в ночное время по городу составляет 350р.</p>
              <p>Оформление ночной доставки осуществляется с 8:00 до 22:00.</p>

              <p>
                В праздничные дни время сборки заказов увеличивается. Доставка
                осуществляется в промежутках. Свободный интервал уточняйте у
                менеджера.
              </p>

              <p>
                Доставка на межгород и в отдаленные районы уточняйте у
                менеджера.
              </p>

              <h3>Зоны доставки</h3>
              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A0f28eb6d7750257c16c3d55a8896ab99cd20e785b3898800889ba6c347bfb4f3&amp;source=constructor"
                width="100%"
                height="400"
                frameborder="0"
              ></iframe>
            </div>
          </div>
          <div className="delivery-info-list">
            <h3>ОПЛАТА И ДОСТАВКА ЦВЕТОВ ЦВЕТОВ</h3>
            <p>Оплата принимается с предоплатой 100% от стоимости заказа.</p>
            <p>
              Процесс доставки и контроль качества товара постоянно
              контролируется менеджером. В случае любых изменений просим
              оперативно проинформировать нас по телефону
            </p>
            <p>
              <a href="tel:79308301501">
                <b>8 (930) 830-15-01</b> 
              </a>{" "}
              для внесения корректировок.
            </p>
            <h3>Правила доставки</h3>
            <ol>
              <li>Курьер ждет на адресе доставки не более 10 минут</li>
              <li>
                Замена адреса доставки по инициативе заказчика после отсутствия
                получателя на первом адресе оплачивается заказчиком
                дополнительно. При отказе оплаты повторной доставки букет
                возвращается в магазин, и заказчик может его забрать в течении
                24 часов.
              </li>
              <li>
                В случае отмены заказа после сборки букета и согласования,
                денежные средства не возвращаются.
              </li>
              <li>
                По всем интересующим вас вопросам вам ответит наш менеджер.
              </li>
            </ol>

            <h3>ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ</h3>
            <p className="delivery-info-mark">
              Согласно Перечню непродовольственных товаров надлежащего качества,
              не подлежащих возврату или обмену на аналогичный товар других
              размера, формы, габарита, фасона, расцветки или комплектации{" "}
              <strong>
                (п. 13 введен Постановлением Правительства РФ от 20.10.1998 N
                1222)
              </strong>{" "}
              животные и растения возврату не подлежат.
            </p>
            <p>Дополнительная информация по условиям возврата товара.</p>
            <h3>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h3>
            <p>
              Администрация cайта amourflora.ru (далее «Сайт») очень серьезно
              относится к защите персональных данных посетителей нашего Сайта и
              никогда не предоставляет персональную информацию посетителей кому
              бы то ни было, кроме случаев, когда этого прямо требует
              уполномоченный государственный орган (например, по письменному
              запросу суда). Вся персональная информация посетителя используются
              для связи с ним, для исполнения сделки, заключенной между
              постетителем Сайта с помощью ресурсов Сайта, для анализа
              посещаемости Сайта, для разработки предложений по его улучшению и
              развитию и может быть раскрыта иным третьим лицам только с его
              разрешения.
            </p>
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default DeliveryPage;
