import React from "react";
import loaderGif from "../../assets/ui/loader-flower.gif";

import UiModal from "./UiModal";

import "./UiModal.scss";

const UiLoader = (props) => {
  return (
    <UiModal
      show={props.modalOpen}
      light={true}
    >
      <div id="loader" className="loader">
        <img alt="Загрузка.." className="loader-gif" src={loaderGif} />
      </div>
    </UiModal>
  );

}

export default UiLoader;
