import React, { useState } from "react";

import UiBtnIcon from "../buttons/UiBtnIcon";
import UiMenuList from "./UiMenuList";

import "./UiRightPanel.scss";

const UiRightPanel = (props) => {
  return (
    <div
      className={"right-panel" + (props.open ? " open" : "")}
      onClick={() => props.setOpen()}
    >
      <div className="right-panel-inner">
        {/**
         * close button
         */}
        <div className="right-panel-close">
          <UiBtnIcon
            color="black"
            fill="white"
            icon="close"
            iconSize={8}
            size="36"
            onClick={() => props.setOpen()}
          />
        </div>
        {/**
         * right panel menu
         */}
        <div className="right-panel-menu-mobile">
          <UiMenuList menuList={props.menuList1} vertical={true} />
        </div>
        {/**
         * bottom contacts
         */}
        <div className="right-panel-contacts">
          <div className="right-panel-contacts-wrap">
            <UiBtnIcon
              icon="whatsapp"
              iconSize="24"
              fill="white"
              href="https://wa.me/+79308301501"
              size="36"
              target="_blank"
            />
            <UiBtnIcon
              icon="viber"
              iconSize="24"
              fill="white"
              href="viber://chat?number=%2B79308301501/"
              size="36"
              target="_blank"
            />
            <UiBtnIcon
              icon="telegram"
              iconSize="24"
              fill="white"
              href="https://telegram.me/ananasflowers72"
              size="36"
              target="_blank"
            />
          </div>
          <div className="right-panel-contacts-wrap">
            <UiBtnIcon
              icon="vk"
              iconSize="24"
              fill="white"
              href="https://vk.com/amourflora
              "
              size="36"
              target="_blank"
            />
            <UiBtnIcon
              icon="instagram"
              iconSize="24"
              fill="white"
              href="https://instagram.com/amourflora?igshid=MzRlODBiNWFlZA=="
              size="36"
              target="_blank"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default UiRightPanel;
