import React from "react";
import { useNavigate } from "react-router-dom";
import Container from "../container/Container";

import "./UiPageTitle.scss";

const UiPageTitle = (props) => {
  let navigate = useNavigate();

  if (props.linksList) {
    var path = props.linksList.map((item, index) => {
      return (
        <div key={index} onClick={() => navigate(item.href)}>
          <p>{item.label}</p>
        </div>
      );
    });
  }

  return (
    <div className={"page-title-bg-wrap" + (props.black ? " black" : " black")}>
      <div className="page-title-bg"></div>
      <div className="title-shadow">
        <Container>
          <div className={"page-title-wrap" + (props.large ? " large" : "")}>
            {/*props.pageName && <h2 className="page-title">{props.pageName}</h2>*/}
            <div className="breadcrumbs">
              <a href="/">Amour Flora</a>
              {props.linksList ? path : null}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default UiPageTitle;
