import React from "react";

import "./UiTextInput.scss";

const UiTextInput = (props) => {
  return (
    <div className="form-group">
      <label>{props.label}</label>
      <input
        id={props.id}
        disabled={props.disabled}
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
        className={
          "text-input" +
          (props.btnText ? " text-right" : "") +
          (props.warnText && props.warnText != "" ? " text-input-warn" : "")
        }
        onChange={(e) => { 
          if (props.onChange) props.onChange(e.target.value);
        }}
        onBlur={(e) => {
          if (props.onBlur) props.onBlur(e.target.value);
        }}
      />
      {props.btnText ? (
        <button className="btn" onClick={props.onClick}>
          {props.btnText}
        </button>
      ) : null}
      {props.warnText && props.warnText != "" ? (
        <p className="text-input-warn">* {props.warnText}</p>
      ) : null}
    </div>
  );
};
export default UiTextInput;
