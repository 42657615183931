import React, { useState } from "react";

import { ReactComponent as SvgLogo } from "../../assets/logo/logo.svg";

import UiIcon from "../icon/UiIcon";

import "./UiPostcardCard.scss";

const UiPostcardCard = (props) => {
  const [active, setActive] = useState(false);

  const setCheck = () => {
    let n_val = !active;
    setActive(n_val);
    props.onChange(n_val);
  };

  return (
    <div className="postcard-card">
      <div className="postcard-card-wrap">
        <div className="postcard-card-image">
          <UiIcon fill="white" icon="heart" size={16} />
          <h2>AMOUR FLORA</h2>
        </div>
        <div className="postcard-card-info">
          <h3>Открытка Amour Flora</h3>
          <p>Бесплатно</p>
        </div>
      </div>
      <button
        className={"postcard-card-button" + (active ? " active" : "")}
        onClick={() => setCheck()}
      >
        {active && <UiIcon icon="checked" size={16} />}
      </button>
    </div>
  );
};
export default UiPostcardCard;
