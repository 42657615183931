import React from "react";

import "./UiCategoriesCard.scss";

const UiCategoriesCard = (props) => {
  return (
    <button className="categories-card" onClick={props.onClick}>
      <div
        className="categories-card-image"
        style={{ backgroundImage: "url(" + props.image + ")" }}
      >
        <div className="categories-card-overlay">
          <div className="categories-card-info">
            <h3>{props.title}</h3>
            {props.price && <p>от&nbsp;{props.price}&nbsp;₽</p>}
            <p>{props.text}</p>
          </div>
        </div>
      </div>
    </button>
  );
};
export default UiCategoriesCard;
