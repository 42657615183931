import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

import demo1 from "../../assets/demo/bouquet11.jpg";
import pageBg from "../../assets/banners/title-catalog.jpg";

import demo2 from "../../assets/demo/bouquet8.jpg";
import demo3 from "../../assets/demo/bouquet9.jpg";
import demo4 from "../../assets/demo/demo1.jpg";
import demo5 from "../../assets/demo/bouquet10.jpg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBarNum from "../../components/bars/UiBarNum";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiCheck from "../../components/forms/UiCheck";
import UiPageTitle from "../../components/header/UiPageTitle";
import UiSectionTitle from "../../components/header/UiSectionTitle";

import Env from "../../services/Env";
import {
  getCompositionByStorage as getCompositionByStorageAction,
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
} from "../../redux/modules/storage";
import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
} from "../../redux/modules/login";
import { getCategoryList as getCategoryListAction } from "../../redux/modules/category";

import "react-loading-skeleton/dist/skeleton.css";
import "../../styles/product.scss";

import { convertImages, unCheckInArray } from "../../utilities/array";
import { convertToList } from "../../utilities/storage";

function ProductPage(props) {
  let { title } = useParams();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);

  const [image, setImage] = useState(0);
  const [type, setType] = useState(0);
  const [selectedNum, setSelectedNum] = useState(1);

  const images = [];

  const [item, setItem] = useState(null);
  const [categories, setCat] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [additional, setAdditional] = useState([]);
  const [catalogList, setCatalogList] = useState([]);
  const [addBasket, setAddBasket] = useState([]);
  const [catTitle, setCatTitle] = useState(null);

  const [productTypes, setProductsTypes] = useState([]);
  const [imageLoader, setImageLoader] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    props.getStorageItem(title).then((val) => {
      if (val) {
        setLoader(false);
        setItem(val);
        window.scrollTo({ top: 0, behavior: "smooth" });

        props.getCompositionByStorage(val.id).then((res) => {
          if (res.response) {
            if (res.response.length > 0) {
              let arr = [];
              res.response.map((item, index) => {
                arr.push({
                  option: item.custom_title,
                  price: item.custom_price,
                  text: item.custom_text,
                  storage_id: val.id,
                  image: item.custom_image,
                  images: item.image,
                  id: item.id,
                  index: index + 1,
                });
              });
              setProductsTypes(arr);
              if (arr.length > 0) {
                /*
            
                  setItem({
                    ...val,
                    custom: arr[0].id,
                    price: arr[0].price,
                    customTitle: arr[0].option,
                    image: convertImages(arr[0].image)[0],
                    images: arr[0].image,
                    text: arr[0].text
                      ? arr[0].text
                      : val.text,
                  });
                  setImage(0);
                  */
              }
              setImageLoader(false);
              setLoader(false);
            } else {
              setProductsTypes([]);
              setImage(0);
              setImageLoader(false);
              setLoader(false);
            }
          } else {
            setImage(0);
            setImageLoader(false);
            setLoader(false);
          }
        });
      } else {
        window.location.href = "/404";
      }
    });
    /*
    props.getStorageListByCat(5).then((val) => {
      setGifts(convertToList(val));
    });
   
    props.getStorageListByCat(7).then((val) => {
      setAdditional(convertToList(val).reverse());
    });
    */
    props.getStorageListByCat(19).then((val) => {
      console.log(convertToList(val));
      setCatalogList(convertToList(val).reverse());
    });

    props.getCategoryList().then((val) => {
      setCat(val);
    });
  }, []);

  useEffect(() => {
    if (item) {
      setCatTitle(
        categories.filter((items) => items.id == item.category)[0]?.title
      );
    }
  }, [item, categories]);

  var imageButtons = (item ? convertImages(item.imgs) : []).map(
    (item, index) => {
      return (
        <button
          key={index}
          className={
            "product-slider-control-button" + (index == image ? " active" : "")
          }
          onClick={() => {
            setImage(index);
            setType(index);
          }}
          style={{ backgroundImage: "url(" + Env.PUBLIC_URL + item + ")" }}
        ></button>
      );
    }
  );

  return (
    <PageContainer
      page="product"
      pageTitle={` ${
        item ? item.title : ""
      } купить в Владимире с доставкой | AmourFlora `}
      pageDescription={`${
        item ? item.title : ""
      } купить в студии декора и флористики ❀ AmourFlora ❀ с доставкой по Владимиру ➤ Доступные цены и огромный каталог букетов`}
      pageKeywords=""
    >
      <UiPageTitle
        image={pageBg}
        linksList={[{ href: "/catalog", label: "Каталог" }]}
        pageName={catTitle}
      />
      <Container>
        <div className="product-info">
          {/**
           * image slider
           */}
          <div className="product-slider">
            <div className="product-slider-image-wrap">
              {loader ? (
                <Skeleton className="product-slider-image" />
              ) : item ? (
                <div
                  className="product-slider-image"
                  style={{
           
                    backgroundImage: "url(" + (item.images
                    ?  `${Env.PUBLIC_URL}${convertImages(item.images)[image]}`
                    : `${Env.PUBLIC_URL}${convertImages(item.imgs)[image]}`) +
                    ")",
                    
                }}
                ></div>
              ) : null}
              <div className="product-slider-control">
                {loader ? (
                  <Skeleton className="product-slider-control-button" />
                ) : (
                  imageButtons
                )}
              </div>
            </div>
          </div>
          {/**
           * product about info
           */}
          <div className="product-about">
            <h3>{loader ? <Skeleton width={200} /> : item.title}</h3>

            <div className="product-about-price">
              <p>{loader ? <Skeleton width={100} /> : item.price + " ₽"}</p>
            </div>

            <div className="product-about-sizes">
              {productTypes.length > 0 && <h3>ВАРИАНТЫ БУКЕТА:</h3>}
              {productTypes.length > 0 && (
                <div className="catalog-card-sizes">
                  {productTypes.map((sizeItem, sizeIndex) => {
                    return (
                      <button
                        className={item.custom == sizeItem.id ? "active" : ""}
                        key={sizeIndex}
                        onClick={() => {
                          console.log(productTypes[sizeIndex])
                          setItem({
                            ...item,
                       

                            image: convertImages(productTypes[sizeIndex].image)[0],
                            images: productTypes[sizeIndex].image,
                            custom: productTypes[sizeIndex].id, 
                            
                            price: productTypes[sizeIndex].price, 
                            sale: productTypes[sizeIndex].sale, 
                            sale_count: productTypes[sizeIndex].sale_count, 
      
                            customTitle: productTypes[sizeIndex].option,
                            text: productTypes[sizeIndex].text

                          });
                          setImage(0);
                        }}
                      >
                        {sizeItem.option}
                      </button>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="product-add">
              <UiBarNum
                loader={loader}
                num={selectedNum}
                onMinus={() =>
                  selectedNum > 1 ? setSelectedNum(selectedNum - 1) : 1
                }
                onPlus={() => setSelectedNum(selectedNum + 1)}
              />
              {loader ? (
                <Skeleton width={273.36} height={52} />
              ) : props.checkInUserBasket(item.id) ? (
                <UiBtnColor
                  color="brand"
                  text={"В корзине"}
                  onClick={() => {
                    navigate("/order");
                  }}
                />
              ) : (
                <UiBtnColor
                  color="brand"
                  text={"Добавить в корзину"}
                  onClick={() => {
                    item.image =  (item.images
                    ?  `${Env.PUBLIC_URL}${convertImages(item.images)[image]}`
                    : `${Env.PUBLIC_URL}${convertImages(item.imgs)[image]}`) ;
                    item.count = selectedNum;
                    props.addUserBasket(item);
                  }}
                />
              )}
            </div>
            {/*
            <div className="product-delivery-info">
              <p>
                {loader ? (
                  <Skeleton width={280} />
                ) : (
                  "Доставка по городу бесплатно"
                )}
              </p>
            </div>
                */}
            <div className="product-description">
              <h4>Описание</h4>
              <p>
                {loader ? <Skeleton count={2} width={"100%"} /> : item.text}
              </p>
            </div>
            {loader ? (
              <Skeleton
                className="product-add-skeleton"
                count={3}
                width={"100%"}
                height={20}
              />
            ) : (
              <div className="product-description">
                <h4>Наличие и внешний вид цветов</h4>
                <p>
                  Сорт и цвет цветов может отличаться от фото. О наличии
                  определенного сорта и цвета уточняйте по телефону.
                </p>
                <h4>КАК УХАЖИВАТЬ</h4>
                <p>
                  Чтобы букет радовал как можно дольше, букет следует поставить
                  в прохладное место вдали от прямых солнечных лучей.
                </p>
              </div>
            )}
          </div>
        </div>
        {loader ? null : catalogList.length > 0 ? (
          <div className="product-more">
            <UiSectionTitle
              title="Также вам могут понравиться"
              text="Обратите внимание на наши новинки и популярные товары"
            />
            <UiCatalog list={catalogList} medium />
          </div>
        ) : null}
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ storage, category, login }) => ({ storage, category, login }),
  {
    getCompositionByStorage: getCompositionByStorageAction,
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,

    getCategoryList: getCategoryListAction,
    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
  }
)(ProductPage);
