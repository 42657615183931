import React from "react";

import Container from "../container/Container";

import "./UiCatalog.scss";

const UiCatalogBanner = (props) => {
  const categoriesList = [{ image: null, title: "Каталог букетов" }];

  var categories = categoriesList.map((item, index) => {
    return <div key={index} className={"catalog-categories-card"}></div>;
  });

  return (
    <div className="catalog-categories">
      {categories}
      <div className="catalog-categories-overlay">
        <Container>
          <p>
            <a href="/">Amour Flora</a> / {props.title}
          </p>
        </Container>
      </div>
    </div>
  );
};
export default UiCatalogBanner;
