import React from "react";
import Skeleton from "react-loading-skeleton";

import UiBtnIcon from "../buttons/UiBtnIcon";

import "react-loading-skeleton/dist/skeleton.css";
import "./UiBarDeliveryType.scss";

const UiBarDeliveryType = (props) => {
  return (
    <div className="order-delivery-type">
      <button
        className={
          "order-delivery-type-btn" + (props.movingType === 0 ? " active" : "")
        }
        onClick={() => props.onClick(0)}
      >
        Доставка
      </button>
      <button
        className={
          "order-delivery-type-btn" + (props.movingType === 1 ? " active" : "")
        }
        onClick={() => props.onClick(1)}
      >
        Самовывоз
      </button>
    </div>
  );
};
export default UiBarDeliveryType;
