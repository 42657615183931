import React from "react";

import subscribeBg from "../../assets/banners/subscribe.jpg";

import UiBtnColor from "../buttons/UiBtnColor";

import "./UiSubscribeCard.scss";

const UiSubscribeCard = (props) => {
  return (
    <button className="subscribe-card" onClick={props.onClick}>
      <div
        className="subscribe-card-image"
        style={{ backgroundImage: "url(" + subscribeBg + ")" }}
      >
        <div className="subscribe-card-overlay">
          <h3>{props.title}</h3>
          <UiBtnColor color="black" text="Узнать больше" />
        </div>
      </div>
    </button>
  );
};
export default UiSubscribeCard;
