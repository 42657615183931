import Env from "../../services/Env";
import { getSettings, getIntervalDate } from "../../services/Settings";
import OrderConstant from "../../constants/OrderConstant";

const moduleName = "setting";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_INTERVAL_LIST = `${moduleName}/SET_INTERVAL_LIST`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
    list: [],
    listInterval: [],
    isFetching: false,

}

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_LIST:
            return { ...state, list: payload }
        case SET_INTERVAL_LIST:
            return { ...state, listInterval: payload }

        case SET_API_REQUEST:
            return { ...state, isFetching: payload }

        default:
            return state;
    }

}



export const getSettingList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getSettings().then((res) => {
            if (res.response) {
                dispatch({
                    type: SET_LIST,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const getSettingValue = ( _title, _settings ) => (dispatch, getState) => {
    try {
        let res = null;
        let list = _settings;
        if(list){
            if (list.length > 0) { 
                list.map((item) => {
                    if (item.title == _title) res = item.value;
                })
            }
        }
        return res;

    } catch (error) {
        console.error(error);
    }

}

export const getSettingDelivery = (_settings, _distance, _less) => (dispatch, getState) => {
    try {
        let price = 0;
        let maxPrice = 0;
        let list = _settings;

        if (list.length > 0) {

            list.map((item) => {
                if (item.title.includes("sum_delivery")) {
                    let interval = item.title.split("_")[3];
                    let type = item.title.split("_")[4];
                    maxPrice = item.value;

                    if (
                        parseInt(interval.split("-")[0]) <= _distance &&
                        _distance <= parseInt(interval.split("-")[1]) && price == 0) {
                        if (_less && type == "less") price = item.value;
                        if (!_less && type != "less") price = item.value;
                    }
                }

            })
            if (price == 0) price = maxPrice;

            console.log(_distance, _less, price);
        }
        return price;

    } catch (error) {
        console.error(error);
    }

}

export const getIntervalList = (data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });
        return getIntervalDate(data).then((res) => {
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            if (res.response) {

                dispatch({
                    type: SET_INTERVAL_LIST,
                    payload: res.response
                });

                if (res.response.length > 0) {
                    let arr = res.response.map((item, index) => { if (item.order_limit > item.total) return item.title });
                    arr.unshift(null)
                    return arr;
                } else return (OrderConstant.ORDER_TIME_TYPE);
            }
        })
    } catch (error) {
        console.error(error);
    }

}
