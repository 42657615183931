import React, { useState } from "react";

import UiBtnColor from "../buttons/UiBtnColor";
import UiBtnIcon from "../buttons/UiBtnIcon";
import UiCheckList from "../forms/UiCheckList";
import UiModal from "./UiModal";
import OrderConstant from "../../constants/OrderConstant";

import "./UiOrderAddress.scss";

const UiOrderPickup = (props) => {
  const [selfDeliveryPoint, setSelfDeliveryPoint] = useState(0);

  return (
    <UiModal show={props.modalOpen}>
      <div className="order-address">
        <h2>Выберите пункт самовывоза</h2>
        <div className="modal-close-btn">
          <UiBtnIcon
            color="black"
            fill="white"
            icon="close"
            iconSize={12}
            onClick={() => props.modalClose()}
            size={40}
          />
        </div>
        <UiCheckList
          colored={true}
          checkList={OrderConstant.ORDER_SELF_DELIVERY_NAMES}
          onChange={(val) => {
            setSelfDeliveryPoint(val);
          }}
          selected={selfDeliveryPoint}
        />

        <div className="order-time-button">
          <UiBtnColor color="black" text="Сохранить" />
        </div>
      </div>
    </UiModal>
  );
};
export default UiOrderPickup;
