import React, {useEffect} from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import  { 
  getStoredUser as getStoredUserAction, 
  showUserInfo as showUserInfoAction,
  logoutUser as logoutUserAction,
  getStoredUserBasket as getStoredUserBasketAction
} from "../../redux/modules/login"
import {getMaterialList as getMaterialListAction} from '../../redux/modules/material'
 
 
function AppInfoLoader(props ) {
  const navigate = useNavigate();
  let location = useLocation().key;
  useEffect(() => { 
    props.getStoredUser(); 
    props.getStoredUserBasket();
  
  }, [location])
 
  useEffect(() => {  
    if( props.login.user ){  
      if(props.login.user.api_token && props.login.user.id ){
       
        props.showUserInfo( props.login.user.api_token, props.login.user.id ).then((val) => { 
          if(val){
    
          } else {
            
            props.logoutUser().then(() => {
              document.location.href="/";
            });
            
          }
         }) 
      }
     
    }  
  }, [props.login.user])

    return null;
}

export default connect(
  ({ login, category }) => ({ login, category }),
  {
    getStoredUser : getStoredUserAction, 
    showUserInfo: showUserInfoAction,
    logoutUser: logoutUserAction,

    getStoredUserBasket: getStoredUserBasketAction,
    getMaterialList: getMaterialListAction
  }
)(AppInfoLoader);

