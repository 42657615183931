import React from "react";

import "./UiOrderInfoCard.scss";

const UiOrderInfoCard = (props) => {
  return (
    <button className="order-info-card" onClick={props.onClick}>
      <div className="order-info-card-info">
        <h3>{props.title}</h3>
        <p>{props.text}</p>
      </div>
      <p>Редактировать</p>
    </button>
  );
};
export default UiOrderInfoCard;
