import Env from "../../services/Env";
import {
  getList,
  getListByCat,
  getItemTitle,
  makeStorage,
  updateStorage,
  getStorageComposition,
} from "../../services/BaseStorage";

const moduleName = "storage";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_ITEM = `${moduleName}/SET_ITEM`;
const SET_COMPOSITION_LIST = `${moduleName}/SET_COMPOSITION_LIST`;
const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
  list: [],
  compositionList: [],
  item: null,
  error: false,
  isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_LIST:
      return { ...state, list: payload };
    case SET_ITEM:
      return { ...state, item: payload };
    case SET_COMPOSITION_LIST:
      return { ...state, compositionList: payload };
    case SET_ERROR:
      return { ...state, error: payload };
    case SET_ERROR_TYPE:
      return { ...state, errorType: payload };

    case SET_API_REQUEST:
      console.log("fetching API request", payload);
      return { ...state, isFetching: payload };

    default:
      return state;
  }
};

export const setList = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LIST,
      payload: value,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getStorageItem = (value) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getItemTitle(value).then((res) => {
      if (res.response) {
        dispatch({
          type: SET_ITEM,
          payload: res.response,
        });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getStorageList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getList().then((res) => {
      if (res.response) {
        dispatch({
          type: SET_LIST,
          payload: res.response,
        });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getStorageListByCat = (_id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getListByCat(_id).then((res) => {
      console.log(res);
      if (res.response) {
        dispatch({
          type: SET_LIST,
          payload: res.response,
        });
        return res.response;
      } else {
        return [];
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
    });
  } catch (error) {
    console.error(error);
  }
};

export const addStorage = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return makeStorage(getState().login.user.api_token, _data).then((res) => {
      console.log(res);
      if (res.response) {
        dispatch(getStorageList());
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res;
    });
  } catch (error) {
    console.error(error);
  }
};

export const update = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return updateStorage(getState().login.user.api_token, _data).then((res) => {
      console.log(res);
      if (res.response) {
        dispatch(getStorageList());
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getCompositionByStorage = (_id) => async (dispatch, getState) => {
  try {
    let _data = { id: _id };

    return getStorageComposition(_data).then((res) => {
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      if (res.status == 200) {
        dispatch({
          type: SET_COMPOSITION_LIST,
          payload: res.response,
        });
      } else {
        dispatch({
          type: SET_COMPOSITION_LIST,
          payload: [],
        });
      }
      return res;
    });
  } catch (error) {
    console.error(error);
  }
};
