import React from "react";
import Skeleton from "react-loading-skeleton";

import UiBtnIcon from "../buttons/UiBtnIcon";

import "react-loading-skeleton/dist/skeleton.css";
import "./UiBarNum.scss";

const UiBarNum = (props) => {
  return (
    <div className={"product-add-buttons" + (props.small ? " small" : "")}>
      {props.loader ? (
        <Skeleton width={24} height={24} />
      ) : (
        <UiBtnIcon
          color={props.small ? "gray" : "black"}
          fill={props.small ? "white" : "white"}
          icon="remove"
          iconSize={8}
          size="24"
          onClick={() => props.onMinus()}
        />
      )}
      {props.loader ? <Skeleton width={44} height={24} /> : <p>{props.num}</p>}
      {props.loader ? (
        <Skeleton width={24} height={24} />
      ) : (
        <UiBtnIcon
          color={props.small ? "gray" : "black"}
          fill={props.small ? "white" : "white"}
          icon="add"
          iconSize={8}
          size="24"
          onClick={() => props.onPlus()}
        />
      )}
    </div>
  );
};
export default UiBarNum;
