import React from "react";

import UiIcon from "../icon/UiIcon";

import "./UiBtnTopScroll.scss";

const UiBtnTopScroll = (props) => {
  return (
    <button
      className={"btn-top-scroll" + (props.active ? " active" : "")}
      onClick={props.onClick}
    >
      <span>
        <UiIcon icon="down" size={20} />
      </span>
    </button>
  );
};
export default UiBtnTopScroll;
