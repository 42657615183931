import React from "react";

import { ReactComponent as SvgInstagram } from "../../assets/social/instagram.svg";
import { ReactComponent as SvgLogo } from "../../assets/logo/logo.svg";
import { ReactComponent as SvgVk } from "../../assets/social/vk.svg";

import Container from "../container/Container";

import "./UiFooter.scss";

const UiFooter = (props) => {
  const nowYear = new Date().getFullYear();

  var menu = props.menuList.map((item, index) => {
    return (
      <div key={index} className="footer-link-item">
        <a href={item.page}>{item.title}</a>
      </div>
    );
  });

  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="footer-wrap footer-logo-wrap">
          <SvgLogo className="footer-logo" />
          <div className="footer-logo-info">
            <p>
              Круглосуточная доставка цветов&nbsp;
              <br />
              г. Владимир
            </p>
            <div className="footer-link-item">
              <a href="/policy">Политика конфиденциальности</a>
            </div>
          </div>
        </div>
        <div className="footer-wrap footer-menu-wrap">
          <div className="footer-social">
            <a className="footer-social-link" href="https://vk.com/amourflora">
              <SvgVk className="footer-social-icon" />
            </a>
            <a className="footer-social-link" href="https://instagram.com/amourflora?igshid=MzRlODBiNWFlZA==">
              <SvgInstagram className="footer-social-icon" />
            </a>
          </div>
          <div className="footer-menu">{menu}</div>
        </div>
      </div>
      <div className="sub-footer">
        <Container>
          <div className="sub-footer-wrap">
            <p>
              Copyright © {nowYear} - All rights reserved. Сайт разработан{" "}
              <a href="http://devkot.ru/">DEVKOT</a>
            </p>
          </div>
        </Container>
      </div>
    </footer>
  );
};
export default UiFooter;
