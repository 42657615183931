import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

import "./styles/index.scss";

var WebFont = require("webfontloader");

WebFont.load({
  google: {
    families: ["Montserrat:200,300,400,500,600,700", "Cormorant Infant"],
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
