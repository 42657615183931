import React from "react";

import { ReactComponent as SvgCalendar } from "../../assets/subscribe/calendar.svg";
import { ReactComponent as SvgGift } from "../../assets/subscribe/gift.svg";
import { ReactComponent as SvgSeason } from "../../assets/subscribe/autumn.svg";
import { ReactComponent as SvgStar } from "../../assets/subscribe/star.svg";

import "./UiSubscribeInfoCard.scss";

const UiSubscribeInfoCard = (props) => {
  return (
    <div className="subscribe-info-card" onClick={props.onClick}>
      <div className="subscribe-info-card-image">
        {props.icon == "calendar" ? (
          <SvgCalendar className="subscribe-info-card-image-icon" />
        ) : props.icon == "gift" ? (
          <SvgGift className="subscribe-info-card-image-icon" />
        ) : props.icon == "season" ? (
          <SvgSeason className="subscribe-info-card-image-icon" />
        ) : props.icon == "star" ? (
          <SvgStar className="subscribe-info-card-image-icon" />
        ) : null}
      </div>
      <h3>{props.title}</h3>
      <p>{props.text}</p>
    </div>
  );
};
export default UiSubscribeInfoCard;
