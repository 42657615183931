import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as SvgBag } from "../../assets/ui/bag.svg";
import { ReactComponent as SvgMastercard } from "../../assets/payment/mastercard.svg";
import { ReactComponent as SvgMaestro } from "../../assets/payment/maestro.svg";
import { ReactComponent as SvgMir } from "../../assets/payment/mir.svg";
import { ReactComponent as SvgVisa } from "../../assets/payment/visa.svg";
import { ReactComponent as SvgYandex } from "../../assets/payment/yandex.svg";

import showNotify from "../../components/notify/UiNotify";
import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBarDeliveryType from "../../components/bars/UiBarDeliveryType";
import UiBasketCard from "../../components/cards/UiBasketCard";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiCheck from "../../components/forms/UiCheck";
import UiCheckList from "../../components/forms/UiCheckList";
import UiGiftCard from "../../components/cards/UiGiftCard";
import UiLoader from "../../components/modals/UiLoader";
import UiOrderAddress from "../../components/modals/UiOrderAddress";
import UiOrderInfoCard from "../../components/cards/UiOrderInfoCard";
import UiOrderPickup from "../../components/modals/UiOrderPickup";
import UiOrderTime from "../../components/modals/UiOrderTime";
import UiPageTitle from "../../components/header/UiPageTitle";
import UiPostcardCard from "../../components/cards/UiPostcardCard";
import UiSelectDate from "../../components/forms/UiSelectDate";
import UiSelectOption from "../../components/forms/UiSelectOption";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextInputMasked from "../../components/forms/UiTextInputMasked";
import UiTextArea from "../../components/forms/UiTextArea";
import UiMapAddress from "../../components/map/UiMapAddress";

import OrderConstant from "../../constants/OrderConstant";

import {
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
} from "../../redux/modules/storage";
import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
  getStoredUser as getStoredUserAction,
  getStoredUserBasket as getStoredUserBasketAction,
  getStoredBasket as getStoredBasketAction,
  clearUserBasket as clearUserBasketAction,
  changeUserBasket as changeUserBasketAction,
} from "../../redux/modules/login";
import {
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction,
  getSettingDelivery as getSettingDeliveryAction,
  getIntervalList as getIntervalListAction,
} from "../../redux/modules/setting";
import {
  addOrder as addOrderAction,
  getPayLink as getPayLinkAction,
  checkPromo as checkPromoAction,
} from "../../redux/modules/order";

import Env from "../../services/Env";

import "./Order.scss";

import { convertToList } from "../../utilities/storage";
import { formatDateYMD, excludeOldTime } from "../../utilities/date";
import { formatDate, checkIsNight } from "../../utilities/date";
import { getPrice, getTotalPrice } from "../../utilities/order";
import { toArrayList } from "../../utilities/array";

function OrderPage(props) {
  let navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState([]);
  const [basketList, setBasket] = useState([]);
  const [gifts, setGifts] = useState([]);

  const [discount, setDiscount] = useState(0);
  const [deliveryPrice, setDP] = useState(350);
  const [freeDeliveryPrice, setFDP] = useState(9999);
  const [defaultDeliveryPrice, setDefaultDeliveryPrice] = useState(250);
  const [deliveryZone, setDeliveryZone] = useState(0);

  const [selfDeliveryPoint, setSelfDeliveryPoint] = useState(0);

  const [promoCode, setPromoCode] = useState("");

  const [postcard, setPostcard] = useState(false);

  const [rName, setRName] = useState("");
  const [rPhone, setRPhone] = useState("");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [emptyAddr, setEmptyAddr] = useState(false);
  const [flatNum, setFlatNum] = useState("");

  const [comment, setComment] = useState("");

  const [addressSelect, setAddressSelect] = useState(false);
  const [timeSelect, setTimeSelect] = useState(false);
  const [pickupSelect, setPickupSelect] = useState(false);

  const [distance, setDistance] = useState(null);
  const [paymentType, setPaymentType] = useState(0);
  const [movingType, setMovingType] = useState(0);
  const [deliveryType, setDeliveryType] = useState(0);

  const [warnPhone, setWarnPhone] = useState(false);
  const [warnName, setWarnName] = useState(false);
  const [warnRName, setWarnRName] = useState(false);
  const [warnPT, setWarnPT] = useState(false);
  const [warnT, setWarnT] = useState(false);
  const [warnAddress, setWarnAddress] = useState(false);

  const [call, setCall] = useState(false);

  const [stopSelling, setStopSelling] = useState(false);
  const [disableOrderRequestAddress, setDisableOrderRequestAddress] =
    useState(false);
  const [disableOrderRequestDate, setDisableOrderRequestDate] = useState(false);

  const [loading, setLoading] = useState(false);

  const [basketFix, setBasketFix] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [postcardText, setPostcardText] = useState("");

  const [deliveryTimeType, setDeliveryTimeType] = useState(0);
  const [deliveryInterval, setDeliveryInterval] = useState(
    excludeOldTime(new Date(), OrderConstant.ORDER_TIME_TYPE)
  );

  const handleScroll = () => {
    const position = window.pageYOffset;
    //console.log(window.pageYOffset);
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (scrollPosition > 220) {
      setBasketFix(true);
    } else {
      setBasketFix(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    setLoading(true);
    props.getStoredUser();
    props.getStoredUserBasket(false);
    props.getSettingList().then((setting) => {
      if (setting) {
        if (setting.length > 0) {
          setSettings(setting);
        }

        setStopSelling(
          props.getSettingValue("stop_site_selling", setting) == 1
        );

        setDisableOrderRequestAddress(
          props.getSettingValue("disable_order_request_user_adress", setting) ==
            1
        );

        setDisableOrderRequestDate(
          props.getSettingValue("disable_order_request_date", setting) == 1
        );

        if (props.getSettingValue("stop_site_selling", setting) == 1)
          alert("Внимание. Прием заказов приостановлен !");
      }
      setLoading(false);
    });
    props.getStorageListByCat(5).then((val) => {
      setGifts(convertToList(val).reverse());
    });

    props.getIntervalList(formatDateYMD(new Date(), "-")).then((data) => {
      setDeliveryInterval(data);
    });
  }, []);

  useEffect(() => {
    if (props.login.user) {
      setUser(props.login.user);
      setName(props.login.user.name ? props.login.user.name : "");
      setPhone(props.login.user.phone);
      // setDiscount(props.login.user.discount);
    }
    console.log("props.login.user", props.login.user);
  }, [props.login.user]);

  useEffect(() => {
    console.log(props.login.basketList);
    setBasket(props.getStoredBasket());
  }, [props.login.basketList]);

  useEffect(() => {
    if (settings.length > 0) {
      setDefaultDeliveryPrice(
        props.getSettingValue("min_sum_delivery", settings)
      );
    }
  }, [settings]);

  useEffect(() => {
    setDeliveryInterval(excludeOldTime(date, OrderConstant.ORDER_TIME_TYPE));

    props.getIntervalList(formatDateYMD(new Date(date), "-")).then((data) => {
      setLoading(false);
      let datesArr = excludeOldTime(date, data);
      setDeliveryInterval(datesArr);
      if (datesArr.length > 0) setTime(datesArr[0]);
    });
  }, [date]);

  useEffect(() => {
    if (movingType == 1) {
      setDP(0);
    } else {
      console.log("checkIsNight(time) ", checkIsNight(time), time);
      if (deliveryType != null) {
        if (deliveryType == 0) {
          if (time != "" && time !== null) {
            let _dpPrice = props.getSettingValue(`sum_delivery_zone_${0}`, settings);
            let _dpPriceResult = checkIsNight(time)
              ? _dpPrice * 0.8 + _dpPrice
              : _dpPrice;

            setDP(_dpPriceResult);
          } else {
            setDP(props.getSettingValue(`sum_delivery_zone_${0}`, settings));
          }
        } else {
          let _dpPrice = props.getSettingValue(
            `sum_delivery_zone_${deliveryType}`,
            settings
          );
          let _dpPriceResult = checkIsNight(time)
            ? _dpPrice * 0.8 + _dpPrice
            : _dpPrice;
          setDP(_dpPriceResult);
        }
      } else {
        let _dpPrice = props.getSettingValue(`sum_delivery_zone_${3}`, settings);
        let _dpPriceResult = checkIsNight(time)
          ? _dpPrice * 0.8 + _dpPrice
          : _dpPrice;
        setDP(_dpPriceResult);
      }
    }
  }, [
    deliveryZone,
    movingType,
    movingType,
    time,
    address,
    distance,
    settings,
    basketList,
  ]);

  function checkPromoCode(_code) {
    let _line = _code.toLowerCase();
    if (_line) {
      props.checkPromo(_line).then((res) => {
        if (res.length > 0) {
          setDiscount(res[0].value);
          showNotify("Промокод активирован");
        } else {
          showNotify("Промокод не найден");
        }
      });
    } else {
      showNotify("Промокод не найден");
    }
  }

  function checkForm() {
    setWarnPhone(phone.length <= 7);
    setWarnPT(paymentType == null);

    setWarnT(time == "");
    if (!emptyAddr) setWarnAddress(address.length == 0);

    if (name.length < 2) {
      setWarnName(true);
      return false;
    }

    if (movingType == 0) {
      return (
        phone.length > 7 &&
        (emptyAddr || address.length > 0) &&
        paymentType != null
      );
    } else {
      return phone.length > 7 && paymentType != null;
    }
  }

  function pay() {
    setLoading(true);
    let _address = !emptyAddr
      ? `${address}${flatNum.length > 0 ? " кв. " + flatNum : ""}`
      : "Узнать адрес у получателя";
    let type = OrderConstant.ORDER_SELF_DELIVERY[selfDeliveryPoint];
    let _comment = comment;
    let _postcard = "";
    let _time = time
      ? time != ""
        ? time
            .replace(" ", "")
            .split("-")
            .map((item) => {
              return `${Math.round(item.split(":")[0])}:${item.split(":")[1]}`;
            })
            .join("-")
        : ""
      : "";

    if (postcard) {
      _comment = _comment + " Бесплатная открытка: " + postcardText;
    }
    if (deliveryType == null) {
      _comment = _comment + " УТОЧНИТЬ СТОИМОСТЬ ДОСТАВКИ ";
    }
    if (promoCode.length > 0) {
      _comment = _comment + " ПРОМОКОД: " + promoCode;
    }

    basketList.map((bItem) => {
      _postcard = _postcard + " " + Env.HOST_URL + "product/" + bItem.title_url;

      _comment =
        _comment +
        " " +
        `${bItem.title}${bItem.customTitle ? " | " + bItem.customTitle : ""}`;
    });

    props
      .addOrder(
        {
          address_a: _address,
          flat: flatNum,
          date: formatDate(date),
          time: _time,
          moving_types: movingType,
          payment_type: paymentType,
          order_type: type,
          price: getTotalPrice(basketList, deliveryPrice, discount, false),
          price_discount: discount,
          price_delivery: deliveryPrice ? deliveryPrice : 0,
          user_phone: phone,
          user_name: name,

          recipient_name: rName,
          recipient_phone: rPhone,

          postcard: _postcard,
          text: _comment,

          comment: "",
          user_call: call ? 1 : 0,
        },
        basketList,
        user
      )
      .then((res) => {
        if (res) {
          props.clearUserBasket();
          if (paymentType == 0) {
            setLoading(false);

            props
              .getPayLink(
                res.id,
                getTotalPrice(basketList, deliveryPrice, discount, false)
              )
              .then((resData) => {
                if (resData) window.location.href = resData;
              });
          } else {
            setTimeout(() => {
              setLoading(false);
              navigate("/order/success");
            }, 500);
          }
        } else {
          alert(
            "Сервер оплаты не доступен. Перезагрузите страницу и повторите попытку " +
              JSON.stringify(res)
          );
        }
      })
      .catch((err) => {
        alert(JSON.stringify(err));
      });
  }

  let basket = basketList.map((item, index) => {
    return (
      <UiBasketCard
        key={index}
        image={item.image}
        title={`${item.title}${
          item.customTitle ? " | " + item.customTitle : ""
        }`}
        text={item.text}
        price={item.price}
        totalPrice={getPrice(item)}
        num={item.count}
        onClick={() => props.changeUserBasket(item.id, 0)}
        onPlus={() => props.changeUserBasket(item.id, item.count + 1)}
        onMinus={() => props.changeUserBasket(item.id, item.count - 1)}
      />
    );
  });

  var giftsList = gifts.map((item, index) => {
    if (index < 5)
      return (
        <UiGiftCard
          key={index}
          item={item}
          selected={props.checkInUserBasket(item.id)}
          onClick={() => {
            props.addUserBasket(item, true);
          }}
        />
      );
  });

  return (
    <PageContainer page="order" pageTitle="Доставка цветов Amour Flora">
      <UiPageTitle
        black
        linksList={[{ href: "/order", label: "Оформление заказа" }]}
      />

      <Container>
        <div
          className={"order-wrap" + (basket.length === 0 ? " empty-order" : "")}
        >
          {basket.length > 0 ? (
            <div className="order-info">
              <h3>Ваш заказ</h3>
              {user ? (
                <div className="order-info-input">
                  <UiPostcardCard onChange={(val) => setPostcard(val)} />
                  {postcard && (
                    <div className="order-info-input-wrap">
                      <UiTextArea
                        label="Напишите текст открытки"
                        maxLength="400"
                        value={postcardText}
                        onChange={(val) => {
                          setPostcardText(val);
                        }}
                      />
                    </div>
                  )}

                  <UiBarDeliveryType
                    movingType={movingType}
                    onClick={(val) => setMovingType(val)}
                  />

                  {movingType === 0 ? (
                    <div className="order-delivery-select">
                      <h3>Куда и когда доставить букет?</h3>

                      <UiTextInput
                        id={"suggest"}
                        selectText
                        placeholder="Введите адрес"
                        value={address}
                        onChange={(v) => {
                          setAddress(v);
                          setWarnAddress(v.length == 0);
                        }}
                        warnText={warnAddress && "Введите адрес"}
                      />

                      <UiMapAddress
                         
                        address={address}
                        onChangeAddress={(line) => {
                          setAddress(line);
                        }}
                        onChange={(t) => {
                          setDeliveryType(t);
                        }}
                      />

                      {!disableOrderRequestAddress && (
                        <UiCheck
                          label="Узнать адрес у получателя"
                          value={emptyAddr}
                          onChange={() => {
                            setEmptyAddr(!emptyAddr);
                          }}
                        />
                      )}

                      {deliveryTimeType === 0 && (
                        <div className="order-time-grid">
                          <div className="order-delivery-row2">
                            <UiSelectDate
                              label="Дата доставки"
                              value={date}
                              onChange={(e) => {
                                setDate(e);
                              }}
                            />
                            <UiSelectOption
                              label="Время доставки"
                              optionList={toArrayList(deliveryInterval)}
                              value={
                                deliveryInterval.map((it, ind) => {
                                  if (it == time) return ind;
                                })[0]
                              }
                              onChange={(e) => {
                                setTime(deliveryInterval[e]);
                                setWarnT(deliveryInterval[e] == null);
                              }}
                              warnText={warnT && "Введите Время доставки"}
                            />
                          </div>
                        </div>
                      )}

                      <UiCheckList
                        colored={true}
                        checkList={
                          !disableOrderRequestDate
                            ? OrderConstant.ORDER_REQUEST_DATE_FULL
                            : OrderConstant.ORDER_REQUEST_DATE
                        }
                        onChange={(val) => {
                          setDeliveryTimeType(val);
                          if (val == 1) {
                            let datesArr = excludeOldTime(
                              date,
                              OrderConstant.ORDER_TIME_TYPE
                            );
                            if (datesArr.length == 0) {
                              var _date = new Date();
                              _date.setDate(_date.getDate() + 1);
                              datesArr = excludeOldTime(
                                _date,
                                OrderConstant.ORDER_TIME_TYPE
                              );
                            }
                            setTime(datesArr[0]);
                          }
                          if (val == 2) {
                            setTime("");
                          }
                        }}
                        selected={deliveryTimeType}
                      />

                      {deliveryTimeType === 2 && (
                        <div className="order-time-grid">
                          <UiSelectDate
                            label="Дата звонка"
                            value={date}
                            onChange={(e) => {
                              setDate(e);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="order-delivery-select">
                      <h3>Я заберу букет из:</h3>
                      <UiOrderInfoCard
                        onClick={() => setPickupSelect(true)}
                        title="Адрес"
                        text="г. Владимир, ул. Усти-на-лабе, 21"
                      />
                      <h3>Дата</h3>

                      <div className="order-time-grid">
                        <div className="order-delivery-row2">
                          <UiSelectDate
                            label="Дата самовывоза"
                            value={date}
                            onChange={(e) => {
                              setDate(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <h3>Покупатель</h3>

                  <div className="order-info-input-wrap">
                    <div className="order-delivery-row2">
                      <UiTextInput
                        label="Имя покупателя"
                        value={name}
                        onChange={(val) => {
                          setName(val);
                          setWarnName(val.length < 3);
                        }}
                        warnText={warnName && "Введите имя покупателя"}
                      />
                      <UiTextInputMasked
                        label="Телефон покупателя"
                        placeholder="+7 (000) 000 00-00"
                        disabled
                        value={phone}
                      />
                    </div>
                  </div>

                  <h3>Получатель</h3>

                  <div className="order-info-input-wrap">
                    <div className="order-delivery-row2">
                      <UiTextInput
                        label="Имя получателя"
                        value={rName}
                        onChange={(val) => {
                          setRName(val);
                          setWarnRName(val.length < 3);
                        }}
                        warnText={warnRName && "Введите имя получателя"}
                      />
                      <UiTextInputMasked
                        label="Телефон получателя"
                        placeholder="+7 (000) 000 00-00"
                        onChange={(v) => setRPhone(v)}
                      />
                    </div>
                    <UiTextArea
                      label="Комментарий для курьера"
                      value={comment}
                      onChange={(val) => {
                        setComment(val);
                      }}
                    />

                    <div className="order-payment-type">
                      <h3>Оплатить с помощью</h3>
                      <div className="order-payment-type-icons">
                        <SvgVisa height={16} />
                        <SvgMastercard height={14} />
                        <SvgMaestro height={14} />
                        <SvgMir height={11} />
                        <SvgYandex height={16} />
                      </div>
                    </div>
                    <UiSelectOption
                      label="Способ оплаты"
                      optionList={[{ text: "Картой онлайн" }]}
                      onChange={(e) => {}}
                    />

                    <span>
                      Наш менеджер позвонит вам для подтверждения заказа
                    </span>

                    {!stopSelling ? (
                      <UiBtnColor
                        color="brand"
                        onClick={() => {
                          if (checkForm()) pay();
                          else showNotify("Заполните все поля");
                        }}
                        text="Оформить заказ"
                      />
                    ) : (
                      <UiBtnColor
                        color="brand"
                        onClick={() => {}}
                        text={`Прием заказов остановлен`}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="order-info-input">
                  <UiBtnColor
                    color="pink"
                    onClick={() => navigate(`/login`)}
                    small
                    text="Войти в личный кабинет"
                  />
                </div>
              )}
            </div>
          ) : null}

          {/** BASKET */}
          <div className="basket-list-wrap">
            <div className={"basket-list" + (basketFix ? " fixed" : "")}>
              {basket.length > 0 && <h3>Моя корзина</h3>}
              {basket.length > 0 ? (
                basket
              ) : (
                <div className="basket-list-empty">
                  <SvgBag className="basket-list-empty-icon" />
                  <h4>Ваша корзина пуста</h4>
                  <UiBtnColor
                    color="brand"
                    onClick={() => navigate("/")}
                    small
                    text="В каталог"
                  />
                </div>
              )}

              {basket.length > 0 && (
                <div className="order-info">
                  <div className="order-info-input-wrap">
                    <div className="order-delivery-row2">
                      <UiTextInput
                        placeholder="Промокод"
                        onChange={(val) => setPromoCode(val)}
                      />
                      <UiBtnColor
                        color="brand"
                        text="Применить"
                        onClick={() => checkPromoCode(promoCode)}
                      />
                    </div>
                  </div>
                </div>
              )}

              {basket.length > 0 ? (
                <div className="basket-list-coast">
                  <p>Стоимость доставки</p>
                  <p>
                    <strong>
                      {deliveryPrice > 0 ? deliveryPrice : "бесплатно"}
                    </strong>
                  </p>
                </div>
              ) : null}

              {discount > 0 && (
                <div className="basket-list-coast">
                  <p>Скидка</p>
                  <p>
                    {" "}
                    <strong>{discount}%</strong>{" "}
                  </p>
                </div>
              )}

              {basket.length > 0 && (
                <div className="basket-list-coast">
                  <p>
                    <b>Общая стоимость заказа</b>
                  </p>
                  <p>
                    <b>
                      {getTotalPrice(
                        basketList,
                        deliveryPrice,
                        discount,
                        false
                      )}{" "}
                      ₽
                    </b>
                  </p>
                </div>
              )}
              {basket.length > 0 && (
                <div className="basket-list-coast">
                  <p>
                    В праздничные дни время сборки заказов увеличивается.
                    <br />
                    Доставка осуществляется в промежутках.
                    <br />
                    Свободный интервал уточняйте у менеджера.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <UiLoader modalOpen={loading} />
        <UiOrderAddress
          modalOpen={addressSelect}
          modalClose={() => setAddressSelect(false)}
          onChange={(address, address2, comment, emptyAddr, type) => {
            console.log(address, address2, comment, emptyAddr, type);
            if (!emptyAddr) setAddress(address);
            setFlatNum(address2);
            setComment(comment);
            setDeliveryType(type);
          }}
        />
        <UiOrderTime
          modalOpen={timeSelect}
          modalClose={() => setTimeSelect(false)}
          onChange={(date, time, type) => {
            //console.log(date, time, type)
            setDate(date);
            if (type != 2) setTime(time);
            setDeliveryTimeType(type);
          }}
        />
        <UiOrderPickup
          modalOpen={pickupSelect}
          modalClose={() => setPickupSelect(false)}
        />
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ storage, login, setting, order }) => ({ storage, login, setting, order }),
  {
    getStoredUser: getStoredUserAction,
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,

    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
    getStoredUserBasket: getStoredUserBasketAction,
    getStoredBasket: getStoredBasketAction,
    clearUserBasket: clearUserBasketAction,
    changeUserBasket: changeUserBasketAction,
    getSettingList: getSettingListAction,
    getSettingValue: getSettingValueAction,
    getSettingDelivery: getSettingDeliveryAction,

    getIntervalList: getIntervalListAction,

    addOrder: addOrderAction,
    getPayLink: getPayLinkAction,
    checkPromo: checkPromoAction,
  }
)(OrderPage);
