import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { getStoredBasket as getStoredBasketAction } from "../../redux/modules/login";
import { getCategoryList as getCategoryListAction } from "../../redux/modules/category";
import { retrieveData, storeData } from "../../services/Storage";


import Container from "../container/Container";
import UiBtnIcon from "../buttons/UiBtnIcon";
import UiMenuList from "../menu/UiMenuList";

import "./UiHeader.scss";

const UiHeader = (props) => {
  let location = useLocation();
  let navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [basket, setBasket] = useState([]);
  const [menu, setMenuList] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setBasket(props.getStoredBasket());
  }, [props.login.basketList]);

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  useEffect(() => {
    setMenuList(retrieveData("menu_amour") ? retrieveData("menu_amour") : []);
    props.getCategoryList().then((res) => {
      let arr = res.filter((item) => { return item.is_menu == 1 }).map((c) => {
        c.page = `/catalog/${c.title_url}`;
        return c;
      });

 
      arr.unshift({
        page: "/catalog",
        title: "Каталог букетов",
        subItems: [
          "Онлайн витрина",
          "Авторские букеты",
          "Цветочные композиции",
          "Коллекция Simple",
          "Моно, дуо и трио букеты",
          "Коллекция сезонности",
          "Свадебные букеты",
          "Новинки",
        ],
      }); 
      arr.push( { page: "/subscribe", title: "Цветочная подписка" });
      arr.push({ page: "/delivery", title: "Доставка и оплата" });
      arr.push( { page: "/contacts", title: "Контакты" });

      storeData("menu_amour", arr);
      setMenuList(arr);
    })
  }, [])


  return (
    <header className={"header-wrap" + (props.headerStick ? " stick" : "")}>
      {/* header */}
      <Container>
        <div className="header">
          <div className="header-inner" style={{ flexGrow: 1, flexShrink: 1 }}>
            <div className="header-left-menu-btn">
              <UiBtnIcon
                fill="brand"
                icon="menu"
                onClick={() => setMenuOpen(!menuOpen)}
                size={24}
                iconSize={24}
              />
            </div>
            <UiBtnIcon
              fill="brand"
              icon="home"
              onClick={() => navigate("/")}
              size={24}
              iconSize={24}
            />
            <nav className={"nav-menu" + (menuOpen ? " active" : "")}>
              <UiMenuList menuList={menu.length > 0 ? menu : props.menuList1} />
            </nav>
          </div>
          <div className="header-inner" style={{ flexGrow: 0, flexShrink: 0 }}>
            <UiBtnIcon
              fill="brand"
              icon="bag"
              onClick={() => navigate("/order")}
              size={24}
              iconSize={24}
              num={basket.length}
            />
            <UiBtnIcon
              fill="brand"
              icon="user"
              onClick={() => navigate("/profile")}
              size={24}
              iconSize={24}
              style={{ marginLeft: 20 }}
            />
          </div>
        </div>
      </Container>
    </header>
  );
};

 
export default connect(({ login, category }) => ({ login, category }), {
  getStoredBasket: getStoredBasketAction,
  getCategoryList: getCategoryListAction
})(UiHeader);
