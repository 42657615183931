import React from "react";

import "./UiTextArea.scss";

const UiTextArea = (props) => {
  return (
    <div className="form-group">
      <div className="text-area-title">
        <label>{props.label}</label>
        {props.maxLength && <span>10 / {props.maxLength}</span>}
      </div>
      <textarea
        disabled={props.disabled}
        maxLength={props.maxLength ? props.maxLength : "500"}
        placeholder={props.placeholder}
        rows="3"
        className="text-area"
        onChange={(e) => props.onChange(e.target.value)}
      ></textarea>
    </div>
  );
};
export default UiTextArea;
