import React, { useEffect, useState } from "react";

import "./UiMap.scss";

const UiMapAddress = (props) => {
  const json = {
    type: "FeatureCollection",
    metadata: { name: "Амурфлора", creator: "Yandex Map Constructor" },
    features: [
      {
        type: "Feature",
        id: 0,
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [40.33684474426264, 56.11539320245719],
              [40.31930909471227, 56.10336603129575],
              [40.322504151918906, 56.09703359870773],
              [40.32319079742671, 56.09559430003413],
              [40.326151956179146, 56.09461074820266],
              [40.3302289138818, 56.09309938768929],
              [40.33357631073238, 56.0943948432046],
              [40.34074317322018, 56.09269155004434],
              [40.344433892824654, 56.091923843888125],
              [40.34859668121581, 56.09281150275645],
              [40.351364720919186, 56.09251162030615],
              [40.35625707016234, 56.09061630897965],
              [40.357966087632484, 56.09194903366632],
              [40.37131275969063, 56.09270474404236],
              [40.37989582853829, 56.107815824545554],
              [40.40123431833999, 56.12004015931155],
              [40.41616885813489, 56.12713564830416],
              [40.43730421199143, 56.133363005429786],
              [40.44090910090746, 56.135519806331615],
              [40.44261204930175, 56.1411105487264],
              [40.45839123060622, 56.150033852336],
              [40.49374324279776, 56.16149598560558],
              [40.50833445983877, 56.1674345095044],
              [40.51228267150874, 56.173276350959725],
              [40.49751979309076, 56.17576604491319],
              [40.45288783508295, 56.17385091010789],
              [40.39211970764155, 56.16982881475536],
              [40.3619073052978, 56.14913706938279],
              [40.33684474426264, 56.11539320245719],
            ],
          ],
        },
        properties: {
          description: "0",
          fill: "#ed4543",
          "fill-opacity": 0.4,
          stroke: "#ed4543",
          "stroke-width": "5",
          "stroke-opacity": 0.4,
        },
      },
      {
        type: "Feature",
        id: 1,
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [40.495517862239836, 56.15461628976063],
              [40.500410211482986, 56.15660423973344],
              [40.504125623017025, 56.15914071717751],
              [40.52171095269044, 56.16384454846096],
              [40.52771910088379, 56.16576018445117],
              [40.52462919609863, 56.17275144163665],
              [40.517934402397465, 56.176390220381606],
              [40.52977903740718, 56.18740020418621],
              [40.56376799004391, 56.18433687496534],
              [40.57200773613767, 56.19122902042812],
              [40.53149565117672, 56.20902797685665],
              [40.442905921947784, 56.205262275934935],
              [40.37956672031215, 56.19790686938884],
              [40.326978800568, 56.17630981366815],
              [40.30878634213549, 56.16267783227148],
              [40.30397982358079, 56.156211655212786],
              [40.30121465321029, 56.13939254957754],
              [40.27179599404212, 56.12903787149332],
              [40.206768695739555, 56.10301215049513],
              [40.20651120367411, 56.10162104624756],
              [40.22273320379619, 56.09826300043295],
              [40.22899884405499, 56.08895487917354],
              [40.27969074865912, 56.09390827444053],
              [40.321620797487626, 56.08755305907115],
              [40.37037262854231, 56.08380984496404],
              [40.445633958684716, 56.09704635811902],
              [40.4677645993096, 56.121382421541945],
              [40.47624585891133, 56.13892609395564],
              [40.478738436295586, 56.14002305725238],
              [40.480758944893175, 56.1415273116315],
              [40.480890879263534, 56.142812085440184],
              [40.479842716665416, 56.147046521171724],
              [40.48148559750353, 56.14851957885139],
              [40.48581558186916, 56.149237755977715],
              [40.489006616137345, 56.150674069799216],
              [40.48742541348338, 56.150048534103426],
              [40.491480913513854, 56.15231218496552],
              [40.495517862239836, 56.15461628976063],
            ],
            [
              [40.371821109679374, 56.09240970285174],
              [40.358774845030936, 56.09202584887362],
              [40.35465497198406, 56.09087426388776],
              [40.349161807921575, 56.092985476615404],
              [40.3441836279899, 56.0917379558621],
              [40.33336896124189, 56.09452083105839],
              [40.32959241094891, 56.093177399282354],
              [40.32238263311688, 56.09586421577284],
              [40.31929272833172, 56.103347926274814],
              [40.33680218878094, 56.11543391167855],
              [40.36117810430829, 56.14936938411642],
              [40.38556259708857, 56.16543226385967],
              [40.392098609831926, 56.16986946612028],
              [40.40279120915162, 56.17056810572045],
              [40.45060629216696, 56.173845837385194],
              [40.497391406920585, 56.17578275139879],
              [40.512240116027044, 56.17329305853326],
              [40.50829190435707, 56.167427275838286],
              [40.4979063910514, 56.1631410943048],
              [40.4854609412223, 56.15871073711707],
              [40.45902508917152, 56.15013593998612],
              [40.44769049527893, 56.14396498711006],
              [40.44267434301673, 56.14111595851717],
              [40.441537086394426, 56.13751575761024],
              [40.440904085066904, 56.135487874776395],
              [40.438554469969866, 56.134082971261954],
              [40.43728234791273, 56.13333886019077],
              [40.435323580347834, 56.13276249357697],
              [40.43144896056225, 56.131633700543006],
              [40.42845946975156, 56.13072078331284],
              [40.418856326964544, 56.1280913328634],
              [40.406002696644954, 56.122219011406166],
              [40.399868777313614, 56.11926301513711],
              [40.37971753301922, 56.10795256058567],
              [40.371821109679374, 56.09240970285174],
            ],
          ],
        },
        properties: {
          description: "1",
          fill: "#56db40",
          "fill-opacity": 0.4,
          stroke: "#56db40",
          "stroke-width": "5",
          "stroke-opacity": 0.4,
        },
      },
      {
        type: "Feature",
        id: 2,
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [40.57762305002186, 56.15335452377335],
              [40.62735824616473, 56.20343048914048],
              [40.54148280039987, 56.23641918667811],
              [40.4449275643119, 56.22770345208648],
              [40.38433538433357, 56.2265080189798],
              [40.33214603965373, 56.22225248209648],
              [40.28494553279334, 56.1972590410777],
              [40.25825218867709, 56.18615616941817],
              [40.204093024248465, 56.15536712097842],
              [40.17645554255901, 56.134284121078146],
              [40.167872473711434, 56.12747758322256],
              [40.16141983912781, 56.1218728744236],
              [40.15483551338479, 56.11516058660571],
              [40.19227084014671, 56.080886287708886],
              [40.24273928497091, 56.07186199310644],
              [40.338183010556875, 56.06456420330448],
              [40.43812784919157, 56.05748089879615],
              [40.48507723578823, 56.05820127000412],
              [40.51455871647771, 56.059822878831994],
              [40.517244848507964, 56.116066204625085],
              [40.57762305002186, 56.15335452377335],
            ],
            [
              [40.563881843887465, 56.18437300514897],
              [40.52956105696497, 56.187396650509584],
              [40.51771642195526, 56.176434542045094],
              [40.52475453841032, 56.173035171221876],
              [40.52775861250701, 56.16599607653746],
              [40.524337884053466, 56.16463510318411],
              [40.517471428975355, 56.16276730434791],
              [40.50412475691733, 56.15922302503771],
              [40.50077736006672, 56.156995708521904],
              [40.49202262984212, 56.15278021697002],
              [40.48579990492758, 56.14933083296669],
              [40.4814786083196, 56.148540200891034],
              [40.479826367566424, 56.14707887955779],
              [40.48081342048389, 56.14156847762177],
              [40.476178563306156, 56.13878901744555],
              [40.467681325147026, 56.12110117378058],
              [40.445193684766146, 56.09673706278925],
              [40.41553918189755, 56.09179520681537],
              [40.381440674273065, 56.085770167944275],
              [40.36975509458319, 56.08432207798474],
              [40.28049117856756, 56.093919202322844],
              [40.22864944272771, 56.089120940315084],
              [40.22418624692692, 56.09794927851095],
              [40.206318124042205, 56.10294776860434],
              [40.301246865497326, 56.13938636282028],
              [40.3038217861516, 56.15634724700758],
              [40.30828498195238, 56.16257393344909],
              [40.3281792848626, 56.17608303812439],
              [40.37967769794853, 56.198100011586504],
              [40.443020746044276, 56.205563757382585],
              [40.53125469379823, 56.20939075358318],
              [40.571423456005256, 56.19101767371602],
              [40.563881843887465, 56.18437300514897],
            ],
          ],
        },
        properties: {
          description: "2",
          fill: "#82cdff",
          "fill-opacity": 0.4,
          stroke: "#82cdff",
          "stroke-width": "5",
          "stroke-opacity": 0.4,
        },
      },
      {
        type: "Feature",
        id: 3,
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [40.68446481233889, 56.20816337617253],
              [40.501130461752865, 56.28156971591016],
              [40.34114205843256, 56.27087342466523],
              [40.218919158041935, 56.22729321963911],
              [40.09600961214349, 56.15109957135596],
              [40.10081613069817, 56.116206610249776],
              [40.19900643831535, 56.06592023438218],
              [40.358994841635685, 56.03287194496208],
              [40.38508737093254, 56.020183075249896],
              [40.56258523470205, 56.03690843440063],
              [40.5590818822431, 56.109052994233906],
              [40.60646042228213, 56.12612319461637],
              [40.64491257071962, 56.16732882669805],
              [40.68446481233889, 56.20816337617253],
            ],
            [
              [40.51753982902042, 56.1165341305071],
              [40.51468114984879, 56.05965855284859],
              [40.42988042963395, 56.05792970485757],
              [40.326196957954245, 56.06561287794185],
              [40.246546079048, 56.07099018436431],
              [40.19195776117691, 56.0807826284874],
              [40.153848935493315, 56.11532422682618],
              [40.202600766548024, 56.155200219867986],
              [40.266115476020666, 56.189291901317446],
              [40.332033444770786, 56.222396831470604],
              [40.38619260919954, 56.22708278231816],
              [40.4437850011674, 56.22775215691687],
              [40.54146032465371, 56.23683536519396],
              [40.6270597875165, 56.203706192001995],
              [40.57796463370792, 56.15315142970941],
              [40.51753982902042, 56.1165341305071],
            ],
          ],
        },
        properties: {
          description: "3",
          fill: "#b51eff",
          "fill-opacity": 0.4,
          stroke: "#b51eff",
          "stroke-width": "5",
          "stroke-opacity": 0.4,
        },
      },
    ],
  };

  const [deliveryZones, setDeliveryZones] = useState([]);
  const [deliveryPoint, setDeliveryPoint] = useState(null);
  const [mainMap, stMainMap] = useState(null);

  useEffect(() => {
    window.ymaps.ready(() => {
      var placemark = null;
      var myMap = new window.ymaps.Map("mapAddress", {
          center: [37.6177, 55.755863],
          controls: [],
          zoom: 13,
        }),
        deliveryPoint = new window.ymaps.GeoObject(
          {
            geometry: { type: "Point" },
          },
          {
            preset: "islands#blackDotIcon",
            draggable: true,
            iconCaptionMaxWidth: "215",
          }
        );

      deliveryPoint.events.add("dragend", (e) => {
        var target = e.get("target");
        target.properties.set(
          "balloonContentBody",
          target.geometry.getCoordinates()
        );
        myMap.geoObjects.add(placemark);

        window.ymaps
          .geocode(placemark.geometry.getCoordinates(), {
            results: 1,
          })
          .then((res) => {
            var newContent = res.geoObjects.get(0)
              ? res.geoObjects.get(0).properties.get("text")
              : "Не удалось определить адрес.";
            props.onChangeAddress(newContent);
            highlightResult(
              deliveryZones,
              deliveryPoint,
              res.geoObjects.get(0)
            );
          });
      });

      myMap.geoObjects.add(deliveryPoint);
      setDeliveryPoint(deliveryPoint);

      stMainMap(myMap);

      myMap.events.add("click", (e) => {
        var position = e.get("coords");
        deliveryPoint.geometry.setCoordinates(position);
        window.ymaps
          .geocode(position, {
            results: 1,
          })
          .then((res) => {
            var newContent = res.geoObjects.get(0)
              ? res.geoObjects.get(0).properties.get("text")
              : "Не удалось определить адрес.";
            props.onChangeAddress(newContent);
            highlightResult(
              deliveryZones,
              deliveryPoint,
              res.geoObjects.get(0)
            );
          });
      });

      // Сравним положение, вычисленное по ip пользователя и
      // положение, вычисленное средствами браузера.
      window.ymaps.geolocation
        .get({
          provider: "browser",
          mapStateAutoApply: true,
        }).then((result) => {
          let firstGeoObject = result.geoObjects.get(0),
            coords = firstGeoObject.geometry.getCoordinates();
          if( props.address == null ||  props.address == ""){
            deliveryPoint.geometry.setCoordinates(coords);
            myMap.setCenter(coords);
            stMainMap(myMap);
          }  else {
            var geocoder = new window.ymaps.geocode(props.address).then((res) => {
              var firstGeoObject = res.geoObjects.get(0),
                coords = firstGeoObject.geometry.getCoordinates();
                deliveryPoint.geometry.setCoordinates(coords);
                myMap.setCenter(coords);
                stMainMap(myMap);
            });
          }
        });

      var suggestView = new window.ymaps.SuggestView("suggest", {
        provider: {
          suggest: function (request, options) {
            return window.ymaps.suggest("Владимир , " + request);
          },
        },
      });

      suggestView.events.add("select", (e) => {
        var addr = e.originalEvent.item.value;
        props.onChangeAddress(addr);
      });

      document.getElementById("suggest").onblur = () => {
        //console.log("document.getElementById ", document.getElementById("suggest").value );
      };

      // Добавляем зоны на карту.
      var _deliveryZones = window.ymaps.geoQuery(json).addToMap(myMap);
   
      // Задаём цвет и контент балунов полигонов.
      _deliveryZones.each(function (obj) {
        obj.events.add("click", (e) => { 
          var position = e.get("coords");
          deliveryPoint.geometry.setCoordinates(position);
  
         
        });

        obj.options.set({
          fillColor: obj.properties.get("fill"),
          fillOpacity: 0.1,
          strokeColor: obj.properties.get("stroke"),
          strokeWidth: obj.properties.get("stroke-width"),
          strokeOpacity: 0.1,
        });
        obj.properties.set("balloonContent", obj.properties.get("description"));
      });

      setDeliveryZones(_deliveryZones);

      // При перемещении метки сбрасываем подпись, содержимое балуна и перекрашиваем метку.
      deliveryPoint.events.add("dragstart", function () {
        deliveryPoint.properties.set({ iconCaption: "", balloonContent: "" });
        deliveryPoint.options.set("iconColor", "black");
      });

      // По окончании перемещения метки вызываем функцию выделения зоны доставки.
      deliveryPoint.events.add("dragend", () => {
        highlightResult(deliveryZones, deliveryPoint, deliveryPoint);
      });
    });
  }, []);

  useEffect(() => {
    if (props.address) {
      var geocoder = new window.ymaps.geocode(props.address).then((res) => {
        var firstGeoObject = res.geoObjects.get(0),
          coords = firstGeoObject.geometry.getCoordinates();
        deliveryPoint.geometry.setCoordinates(coords);
        mainMap.setCenter(coords); 
        highlightResult(deliveryZones, deliveryPoint, firstGeoObject);
      });
    }
  }, [props.address]);

  function highlightResult(deliveryZones, deliveryPoint, obj) {
 
    // Сохраняем координаты переданного объекта.
    var coords = obj.geometry.getCoordinates(),
      // Находим полигон, в который входят переданные координаты.
      polygon = deliveryZones.searchContaining(coords).get(0);
    //console.log(polygon, deliveryZones, deliveryPoint, obj)
    if (polygon) {
      console.log("in zone", polygon.properties.get("description"));
      // Уменьшаем прозрачность всех полигонов, кроме того, в который входят переданные координаты.
      //deliveryZones.setOptions("fillOpacity", 0.4);
      //polygon.options.set("fillOpacity", 0.8);
      // Перемещаем метку с подписью в переданные координаты и перекрашиваем её в цвет полигона.
     // deliveryPoint.geometry.setCoordinates(coords);
      deliveryPoint.options.set("iconColor", polygon.properties.get("fill"));
      // Задаем подпись для метки.

      props.onChange(polygon.properties.get("description"));
    } else {
      console.log("out zone");
      // Если переданные координаты не попадают в полигон, то задаём стандартную прозрачность полигонов.
      //deliveryZones.setOptions("fillOpacity", 0.4);
      // Перемещаем метку по переданным координатам.
    //  deliveryPoint.geometry.setCoordinates(coords);
      // Задаём контент балуна и метки.
      /*
      deliveryPoint.properties.set({
        iconCaption: "Стоимость доставки соглусуеться",
        balloonContent: "Cвяжитесь с оператором",
        balloonContentHeader: "",
      });
      */
      // Перекрашиваем метку в чёрный цвет.
      deliveryPoint.options.set("iconColor", "black");

      props.onChange(null);
    }
  }

  return <div className="map-address" id="mapAddress" style={props.hide ? {display: "none"} : null}></div>;
};
export default UiMapAddress;
