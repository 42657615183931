import React, { useState } from "react";

import UiSubscribeCard from "../cards/UiSubscribeCard";

import "./UiCatalog.scss";

const UiCatalogFilter = (props) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const [activePrice, setActivePrice] = useState(null);

  const setFilter = (val) => {
    let newVal = val;
    setActiveFilter(newVal);
    props.onChange(newVal);
  };

  if (props.filtersList) {
    var filters = props.filtersList.map((item, index) => {
      return (
        <a
          key={index}
          className={
            "btn catalog-filter" + (activeFilter === index ? " active" : "")
          }
          href={item.title_url}
        >
          {item.title}
        </a>
      );
    });
  }

  if (props.pricesList) {
    var prices = props.pricesList.map((item, index) => {
      return (
        <button
          key={index}
          className="catalog-price"
          onClick={() => {
            if (activePrice === index) {
              setActivePrice(null);
            } else {
              setActivePrice(index);
            }
          }}
        >
          <div
            className={
              "catalog-price-check" + (activePrice === index ? " active" : "")
            }
          >
            {activePrice === index ? (
              <div className="catalog-price-check-dot"></div>
            ) : null}
          </div>
          <p>{item}</p>
        </button>
      );
    });
  }

  return (
    <div className="catalog-filters">
      {props.filtersList ? (
        <div className="category-filters-tab">
          <h4>Категории</h4>
          <div className="category-filters-tab-scroll">{filters}</div>
        </div>
      ) : null}
      <div className="category-filters-subscribe">
        <UiSubscribeCard title="Закажи цветочную подписку" />
      </div>
    </div>
  );
};
export default UiCatalogFilter;
