import React from "react";
import UiIcon from "../icon/UiIcon";

import "./UiBtnIcon.scss";

const UiBtnIcon = (props) => {
  return (
    <a
      className={
        "btn btn-icon" +
        (props.color ? " " + props.color : "") +
        (props.round ? " round" : "") +
        (props.size ? " s" + props.size : "")
      }
      href={props.href}
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
      target={props.target}
    >
      <UiIcon icon={props.icon} fill={props.fill} size={props.iconSize} />
      {props.num > 0 && <div className="btn-icon-num"><span>{props.num}</span></div>}
    </a>
  );
};
export default UiBtnIcon;
