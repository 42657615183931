import React from "react";
import Skeleton from "react-loading-skeleton";

import UiBtnIcon from "../../components/buttons/UiBtnIcon";

import "react-loading-skeleton/dist/skeleton.css";
import "./UiCatalogCard.scss";

const UiCatalogCard = (props) => {
  return (
    <div className="catalog-card">
      <div className="catalog-card-image" onClick={props.onClick}>
        <div className="catalog-card-image-wrap">
          {props.skeleton ? (
            <Skeleton className="catalog-card-controls" />
          ) : (
            <img src={props.image} />
          )}
        </div>
        {props.status !== null ? (
          <div className="catalog-card-controls">
            <div className="catalog-card-status">
              <span>{props.status}</span>
            </div>
          </div>
        ) : null}
        <div className="catalog-card-image-overlay">
          <div className="catalog-card-image-info">
            {/*<h3>{props.title}</h3>*/}
            <p>{props.text}</p>
            <div className="catalog-card-image-info-price">
              <a
                className="catalog-card-image-info-add"
                onClick={props.onClick}
              >
                подробнее
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="catalog-card-info">
        <div>
          <h3>
            {props.skeleton ? <Skeleton count={1} width={136} /> : props.title}
          </h3>
          <p>
            {props.skeleton ? <Skeleton count={1} width={136} /> : props.price}{" "}
            {props.skeleton ? "" : "₽"}
          </p>
        </div>
        {props.skeleton ? (
          <Skeleton width={36} height={36} />
        ) : (
          <UiBtnIcon
            color="black"
            icon="bag"
            iconSize="20"
            fill="white"
            onClick={props.addClick}
            size="42"
          />
        )}
      </div>
    </div>
  );
};
export default UiCatalogCard;
