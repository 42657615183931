import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
} from "../../redux/modules/login";

import { ReactComponent as SvgLeafs } from "../../assets/logo/leafs.svg";
import { ReactComponent as SvgMom } from "../../assets/titles/balloon.svg";

import showNotify from "../../components/notify/UiNotify";

import UiCatalogCard from "../cards/UiCatalogCard";
import UiIcon from "../icon/UiIcon";

import "./UiCatalog.scss";

const UiCatalog = (props) => {
  let navigate = useNavigate();

  var catalogList = props.list.map((item, index) => {
    return (
      <UiCatalogCard
        key={index}
        image={item.image}
        addClick={() => {
          console.log(item);
          props.addUserBasket(item);
          showNotify(`Добавлено: ${item.title}`);
        }}
        onClick={() => navigate(`/product/${item.title_url}`)}
        price={item.price}
        status={item.status}
        title={item.title}
        text={item.text}
        skeleton={item.loader}
      />
    );
  });

  return (
    <div className="catalog-wrap">
      {props.title ? (
        <div className="catalog-title">
          {props.icon == "mom" ? (
            <SvgMom className="catalog-title-icon" />
          ) : (
            <SvgLeafs className="catalog-title-icon" />
          )}
          <h3>{props.title}</h3>
          {props.subtitle ? <p>{props.subtitle}</p> : null}
        </div>
      ) : null}
      {props.list.length > 0 ? (
        <div className={"catalog-list" + (props.medium ? " medium" : "")}>
          {catalogList}
        </div>
      ) : (
        <div className="catalog-list-empty">
          <UiIcon fill="gray" icon="clipboard" size={40} />
          <p>В выбранной категории нет товаров</p>
        </div>
      )}
    </div>
  );
};
export default connect(({ login }) => ({ login }), {
  checkInUserBasket: checkInUserBasketAction,
  addUserBasket: addUserBasketAction,
})(UiCatalog);
