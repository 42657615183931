import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import UiIcon from "../icon/UiIcon";

import "./UiMenuList.scss";

const UiMenuList = (props) => {
  let location = useLocation().pathname;

  const [subActive, setSubActive] = useState(false);

  var menuItems = props.menuList.map((item, index) => {
    if (item.subItems) {
      var menuSubItems = item.subItems.map((item2, index2) => {
        return (
          <li key={index2} className="nav-menu-item-sub-item">
            <a href={item.page} className="nav-menu-item-sub-item-button">{item2}</a>
          </li>
        );
      });
    }

    return (
      <li
        key={index}
        className={
          "nav-menu-item" +
          (location === item.page ? " active" : "") +
          (item.mark ? " mark" : "")
        }
      >
        {item.subItems ? (
          <a href={window.innerWidth >= 1170 && item.page} onClick={() => setSubActive(!subActive)}>
            {item.title}
            {item.subItems && <UiIcon fill="#222" icon="down" size={12} />}
          </a>
        ) : (
          <a href={item.page}>{item.title}</a>
        )}
        {item.subItems && (
          <ul className={"nav-menu-item-sub" + (subActive ? " active" : "")}>
            {menuSubItems}
          </ul>
        )}
      </li>
    );
  });

  return (
    <ul className={"nav-menu-list" + (props.vertical ? " vertical" : "")}>
      {menuItems}
    </ul>
  );
};
export default UiMenuList;
