import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReactComponent as SvgLogo } from "../../assets/logo/logo.svg";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { getMaterialList as getMaterialListAction } from "../../redux/modules/material";

import slide1 from "../../assets/banners/home-slide1.jpg";

import "./UiSliderTop.scss";
import Env from "../../services/Env";

const UiSliderTop = (props) => {
  const { width, height } = useWindowDimensions();

  const sliderH = height - 110;

  const [slide, setSlide] = useState([]);
  const [active, setActive] = useState(0);

  const changeClick = (val) => {
    let dir = val;
    if (dir === "prev") {
      setActive(active - 1);
    }

    if (dir === "next") {
      if (active < slide.length - 1) {
        setActive(active + 1);
      } else {
        setActive(0);
      }
    }
  };

  useEffect(() => {
    props.getMaterialList().then((data) => {
      setSlide(
        data
          .filter((item) => item.is_active != 1)
          .map((item2) => {
            return { image: (item2.image = Env.PUBLIC_URL + item2.image) };
          })
      );
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      changeClick("next");
    }, 7000);
  }, [slide, active]);

  var slides = slide.map((item, index) => {
    return (
      <div
        key={index}
        className={
          "slider-top-slide parallax-image" +
          (index === active ? " active" : "")
        }
        style={{
          backgroundImage: "url(" + item?.image + ")",
        }}
      ></div>
    );
  });

  return (
    <div className={"slider-top-wrap" + (props.opacity ? " hidden" : "")}>
      {height ? (
        <div
          className="slider-top"
          style={{ height: width > 640 ? sliderH : 320 }}
        >
          {slide.length > 0 ? (
            <div className="slider-top-scroll parallax">{slides}</div>
          ) : (
            <div className="slider-top-scroll parallax">
              <div className={"slider-top-slide parallax-image active" }
                style={{ backgroundImage: "url(" + slide1 + ")"}} ></div>
            </div>
          )}
        </div>
      ) : null}
      <div className="slider-top-inner">
        {active !== 0 && (
          <div className="slider-top-overlay">
            <SvgLogo fill={"#222"} width={180} height={180} />
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(({ material }) => ({ material }), {
  getMaterialList: getMaterialListAction,
})(UiSliderTop);
