import "./UiNotify.scss";

function dismiss() {
  var notice = document.getElementById("cookie-notice");
  if (notice) notice.parentNode.removeChild(notice);
}

function undismiss() {
  delete window.localStorage.cookieNoticeDismissed;
}

function showNotify(title) {
  var $div = document.createElement("div");
  $div.className = "cookie-notice";
  $div.id = "cookie-notice";

  var $message = document.createElement("div");
  $message.className = "cookie-notice-message";
  $message.innerHTML = `<p>${title}</p>`;
  $div.appendChild($message);

  /*
    var $dismiss = document.createElement('button')
    $dismiss.innerHTML = 'ок'
    $dismiss.onclick = dismiss
    $div.appendChild($dismiss)
    */
  if (document.getElementById("page"))
    document.getElementById("page").appendChild($div);
  else document.body.appendChild($div);

  setTimeout(() => dismiss(), 2000);
}

export default showNotify;
