import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import demo1 from "../../assets/demo/demo1.jpg";
import demo2 from "../../assets/demo/bouquet8.jpg";
import demo3 from "../../assets/demo/bouquet9.jpg";

import pageBg from "../../assets/banners/subscribe-title.jpg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiPageTitle from "../../components/header/UiPageTitle";
import UiSectionTitle from "../../components/header/UiSectionTitle";
import UiSubscribeInfoCard from "../../components/cards/UiSubscribeInfoCard";

import { getCategoryListByTitle as getCategoryListByTitleAction } from "../../redux/modules/category";
import { convertToList } from "../../utilities/storage";

import "../../styles/subscribe.scss";

function SubscribePage(props) {
  const infoList = [
    {
      icon: "calendar",
      title: "Удобно",
      text: "Оформите подписку один раз и наслаждайтесь цветами весь месяц. Время, день и место доставки вы выбираете самостоятельно.",
    },
    {
      icon: "star",
      title: "Выгодно",
      text: "Мы будем радовать вас новым букетом еженедельно в течение месяца. 4 недели — 4 новых букета. Бесплатная доставка в пределах города.",
    },
    {
      icon: "season",
      title: "Сезонность",
      text: "Сезонные растения добавят уникальность каждому букету. Креативные флористы Amour Flora подберут самые свежие цветы.",
    },
    {
      icon: "gift",
      title: "Сюрприз",
      text: "Каждый новый букет неповторим! Он будет не похож на предыдущий. А еще, цветочную подписку можно оформить в подарок близкому человеку.",
    },
  ];

  const catalogList = [
    {
      image: demo1,
      title: "Размер S",
      text: "Описание или состав букета",
      price: "6 000",
      status: null,
    },
    {
      image: demo2,
      title: "Размер M",
      text: "Описание или состав букета",
      price: "8 000",
      status: null,
    },
    {
      image: demo3,
      title: "Размер L",
      text: "Описание или состав букета",
      price: "10 000",
      status: null,
    },
  ];

  const [catalogListOnline, setCatalogListOnline] = useState([
    { loader: true },
    { loader: true },
    { loader: true }, 
  ]);

  async function _load() {
    const list = await props.getCategoryListByTitle("cvetochnaya-podpiska");
    if (list.catalog) setCatalogListOnline(convertToList(list.catalog));
    const data = await props.getMenuCategoryList();
    let arr = data.map((item) => {
      return {
        title: `${item.data.title} (${item.catalog.length})`,
        title_url: `/catalog/${item.data.title_url}`,
      };
    }); 
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    _load();
  }, []);


  var info = infoList.map((item, index) => {
    return (
      <UiSubscribeInfoCard
        key={index}
        icon={item.icon}
        title={item.title}
        text={item.text}
      />
    );
  });

  return (
    <PageContainer
      page="special"
      pageTitle=""
      pageDescription=""
      pageKeywords=""
    >
      <UiPageTitle
        image={pageBg}
        large={true}
        linksList={[{ label: "Цветочная подписка" }]}
        pageName="Подпишись на рассылку букетов"
      />
      <Container>
        <div className="subscribe-about">
          <UiSectionTitle title="Как работает цветочная подписка" />
          <div className="subscribe-info">{info}</div>
        </div>

        <UiCatalog
              title="Выбрать подписку"
              subtitle="Размер выбранной подписки определяет размер букетов."
              list={catalogListOnline}
            />

       
      </Container>
    </PageContainer>
  );
}
 
export default connect(({ category, storage }) => ({ category, storage }), {
  getCategoryListByTitle: getCategoryListByTitleAction,
})(SubscribePage);
