export default {
  STORE_KEY: 'a56z0fzrNpl^2',
  //Dev

  //Prod
  //HOST_URL: 'http://amour.devkot.ru/',
  //API_URL: 'http://amour.devkot.ru/server/public/api/v1',
  //PUBLIC_URL: 'http://amour.devkot.ru/server/public/uploads/',

  HOST_URL: 'https://amourflora.ru/',
  API_URL: 'https://amourflora.ru/server/public/api/v1',
  PUBLIC_URL: 'https://amourflora.ru/server/public/uploads/',


  
  //STORAGE_URL: '',
 
  getHeaders: function () {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
  }
}; 