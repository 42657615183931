import React from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import UiSectionTitle from "../header/UiSectionTitle";
import Env from "../../services/Env";

import "react-loading-skeleton/dist/skeleton.css";
import "./UiCatalog.scss";

const UiCatalogHome = (props) => {
  let navigate = useNavigate();

  var catalogList = props.list.map((item, index) => {
    return (
      
      item.skeleton ? (
          <Skeleton key={index} className="catalog-home-card" />
        ) : (
          <a key={index} className="catalog-home-card" href={`/product/${item.title_url}`}>
            <div className="catalog-home-card-img-wrap">
              <img className="catalog-home-card-img" src={item.imgs && Env.PUBLIC_URL + item.imgs.split(",")[0]} />
            </div>
            <div className="catalog-home-card-overlay">
              <div className="catalog-home-card-info">
                <h3>{item.title}</h3>
                <p>{item.text}</p>
                <div className="catalog-home-card-info-price">
                  <p>{item.price} ₽</p>
                  <a className="catalog-home-card-info-add">в корзину</a>
                </div>
              </div>
            </div>
          </a>
        )
      
    );
  });

  return (
    <div className="catalog-home-wrap">
      <UiSectionTitle
        title="Новинки Amour Flora"
        text="Выберите букет из наших последних уникальных работ"
      /*buttonText="Смотреть все"
buttonClick={props.allClick}*/
      />
      <div className="catalog-home-list">{catalogList}</div>
    </div>
  );
};
export default UiCatalogHome;
