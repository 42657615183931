import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

 

import florist1 from "../../assets/about/florist1.jpg";
import florist2 from "../../assets/about/florist2.jpg";

 

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiCatalogHome from "../../components/catalog/UiCatalogHome";
import UiCategoriesCard from "../../components/cards/UiCategoriesCard";
import UiIcon from "../../components/icon/UiIcon";
import UiSectionTitle from "../../components/header/UiSectionTitle";

import UiLoader from "../../components/modals/UiLoader";

import "../../styles/home.scss";
 
import {
  getStorageListByCat as getStorageListByCatAction, 
} from "../../redux/modules/storage";
import { 
  getMenuCategoryList as getMenuCategoryListAction 
} from "../../redux/modules/category";
import { storeData, retrieveData } from "../../services/Storage"; 
import Env from "../../services/Env";


function HomePage(props) {
  let navigate = useNavigate();

  const [catalogList, setCatalogList] = useState([
    {skeleton: true},
    {skeleton: true},
    {skeleton: true},
    {skeleton: true},
    {skeleton: true},
    {skeleton: true}
  ]);
  const [loader, setLoader] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);

  
  async function _load() {

    let catList = retrieveData("LOADER_SLIDER");
    console.log(catList); 
    if(catList) setCategoriesList(catList); else  setLoader(true);

    const slider = await props.getStorageListByCat(18);
    setCatalogList(slider);

    const data = await props.getMenuCategoryList();
    let arr = [];
    data.map((item) => {
      if(item.data.image) arr.push({
        title: item.data.title,
        image:  item.data.image,
        price: item.data.price,
        text: item.data.description,
        title_url: item.data.title_url,
      });
    })
    setCategoriesList(arr);
    storeData("LOADER_SLIDER", arr);
    setLoader(false);
  }

  useEffect(() => {
    _load();
  }, []);

  var categories = categoriesList.map((item, index) => {
    return (
      <UiCategoriesCard
        key={index}
        onClick={() => navigate(`/catalog/${item.title_url}`)}
        title={item.title}
        image={Env.PUBLIC_URL + item.image}
        price={item.price}
        text={item.text}
      />
    );
  });

  return (
    <PageContainer page="home" pageTitle="" pageDescription="" pageKeywords="">
      <UiCatalogHome allClick={() => navigate("/catalog")} list={catalogList} />
      <div className="home-categories-wrap">
        <Container>
          <div className="home-categories">
            <UiSectionTitle align="left" title="Категории наших букетов" />
            <a href="/catalog" className="home-categories-all">
              <span>Смотреть все букеты</span>
              <UiIcon fill="#222" icon="down" size={12} />
            </a>
            <div className="home-categories-list">{categories}</div>
          </div>
        </Container>
      </div>
      <div className="home-about-wrap">
        <Container>
          <div className="home-about">
            <div className="home-about-images">
              <img className="home-about-image1" src={florist1} />
              <img className="home-about-image2" src={florist2} />
            </div>
            <div className="home-about-info">
              <UiSectionTitle
                align="left"
                title="Ваше цветочное пространство"
                text="Самый большой ассортимент в городе, креативный подход к каждой композиции и круглосуточная доставка цветов"
              />
              <div className="home-about-info-text">
                <h3>Fashion Floristic</h3>
                <p>
                  Только у нас самые красивые работы, самый большой ассортимент
                  и самые креативные идеи!
                </p>
                <p>
                  Опытные обученные флористы обеспечат индивидуальный подход к
                  созданию букета.
                </p>
                <p>Разнообразный выбор цветов сделает ваш букет уникальным.</p>
              </div>
            </div>
          </div>

          <UiLoader modalOpen={loader} />
        </Container>
      </div>
    </PageContainer>
  );
}

export default connect(({ storage }) => ({ storage }), {
  getStorageListByCat: getStorageListByCatAction,
  getMenuCategoryList: getMenuCategoryListAction,
})(HomePage);
