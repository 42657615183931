import * as React from "react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Provider } from "react-redux";

 

import AppInfoLoader from "./components/main/AppInfoLoader";
import AppModalComponent from "./components/main/AppModalComponent";

import UiBtnTopScroll from "./components/buttons/UiBtnTopScroll";
import UiHeader from "./components/header/UiHeader";
import UiFooter from "./components/footer/UiFooter";
import UiRightPanel from "./components/menu/UiRightPanel";
import UiSliderTop from "./components/sliders/UiSliderTop";

import { useContainerDimensions } from "./hooks/useContainerDimensions";
import { useWindowDimensions } from "./hooks/useWindowDimensions";

import AppNavigator from "./navigation/AppNavigator";

import configureStore from "./redux/store";
 
import "./styles/app.scss";

function App() {
  const store = configureStore();
  const [modal, setModal] = useState(false);
  const [rightPanelOpen, setRightPanelOpen] = useState(false);

  let location = useLocation().pathname;

  const { width, height } = useWindowDimensions();
  const sliderH = height - 110;

  const [headerStick, setHeaderStick] = useState(false);
  const [topBtnActive, setTopBtnActive] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location === "/") {
      if (scrollPosition >= sliderH) {
        setHeaderStick(true);
        setTopBtnActive(true);
      } else {
        setHeaderStick(false);
        setTopBtnActive(false);
      }
    } else {
      if (scrollPosition > 0) {
        setHeaderStick(true);
      } else {
        setHeaderStick(false);
      }
    }
  }, [scrollPosition]);

  const setMenuOpen = () => {
    let new_v = !rightPanelOpen;
    setRightPanelOpen(new_v);
  };

  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollTo(0, 0);

  function smoothScroll() {
    if (window.scrollY == sliderH) {
      window.scrollTo({ behavior: "smooth", top: 0 });
    } else {
      window.scrollTo({ behavior: "smooth", top: sliderH });
    }
  }

  useLayoutEffect(() => {
    executeScroll();
  }, [location]);

 
  const menuList1 = [
    {
      page: "/catalog",
      title: "Каталог букетов",
      subItems: [
        "Онлайн витрина",
        "Авторские букеты",
        "Цветочные композиции",
        "Коллекция Simple",
        "Моно, дуо и трио букеты",
        "Коллекция сезонности",
        "Свадебные букеты",
        "Новинки",
      ],
    },
    { page: "/subscribe", title: "Цветочная подписка" },
    { page: "/delivery", title: "Доставка и возврат" },
    { page: "/contacts", title: "Контакты" },
  ];
  return (
    <Provider store={store}>
      <div className="app" id="app">
        <AppInfoLoader />
        {location === "/" ? (
          <UiSliderTop
            opacity={scrollPosition >= sliderH} 
          />
        ) : null}
        <div className="app-top-info">
          <p>Круглосуточная доставка цветов</p>
          <p>
            <a href="tel:79308301501">+7 (930) 830-15-01</a>
          </p>
        </div>
        <div
          className="app-scroll"
          ref={scrollRef}
          style={
            location === "/" ? { marginTop: width > 640 ? sliderH : 0 } : null
          }
        >
          <div className={"app-content" + (headerStick ? " stick" : "")}>
            <UiHeader
              headerStick={headerStick}
              menuList1={menuList1}
              onChangeMenu={() => setMenuOpen()}
            />
            <AppNavigator />
            <UiFooter menuList={menuList1} />
          </div>
        </div>
        <UiRightPanel
          menuList1={menuList1}
          open={rightPanelOpen}
          setOpen={() => setMenuOpen()}
        />

        <UiBtnTopScroll active={topBtnActive} onClick={() => smoothScroll()} />

        {/** modals */}
        <AppModalComponent />
      </div>
    </Provider>
  );
}

export default App;
