import React, { useState } from "react";

import UiBtnColor from "../buttons/UiBtnColor";
import UiBtnIcon from "../buttons/UiBtnIcon";
import UiCheck from "../forms/UiCheck";
import UiMapAddress from "../map/UiMapAddress";
import UiModal from "./UiModal";
import UiTextArea from "../forms/UiTextArea";
import UiTextInput from "../forms/UiTextInput";

import "./UiOrderAddress.scss";

const UiOrderAddress = (props) => {
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [comment, setComment] = useState("");
  const [emptyAddr, setEmptyAddr] = useState(false);
  const [type, setType] = useState(null);

  const [warnAddress, setWarnAddress] = useState(false);

  return (
    <UiModal show={props.modalOpen}>
      <div className="order-address">
        <h2>Адрес доставки</h2>
        <div className="modal-close-btn">
          <UiBtnIcon
            color="black"
            fill="white"
            icon="close"
            iconSize={12}
            onClick={() => props.modalClose()}
            size={40}
          />
        </div>
        <div className="order-address-row">
          <UiTextInput
            id={"suggest"}
            selectText
            placeholder="Введите адрес"
            value={address}
            onChange={(v) => {
              setAddress(v);
              setWarnAddress(v.length == 0);
            }}
            warnText={warnAddress && "Введите адрес"}
          />
          <UiBtnColor 
            color="black" 
            text="Сохранить" 
            onClick={() => {
              props.onChange(address, address2, comment, emptyAddr, type);
              props.modalClose();
            }}
          />
        </div>
        <UiCheck
          label="Узнать адрес у получателя"
          value={emptyAddr}
          onChange={() => {
            setEmptyAddr(!emptyAddr);
          }}
        />
        <UiMapAddress 
          address={address}
          onChangeAddress={(line) => {
            setAddress(line); 
          }}
          onChange={(t) => {
            setType(t)
          }}
        />
        <UiTextInput 
          placeholder="Кв./оф./этаж/подъезд" 
          value={address2}
          onChange={(line) => {
            setAddress2(line);
          }}
        />
        <UiTextArea
          label="Комментарий для курьера"
          value={comment}
          onChange={(val) => {
            setComment(val);
          }}
        />
      </div>
    </UiModal>
  );
};
export default UiOrderAddress;
