import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiCatalogBanner from "../../components/catalog/UiCatalogBanner";
import UiCatalogFilter from "../../components/catalog/UiCatalogFilter";

import { getCategoryListByTitle as getCategoryListByTitleAction } from "../../redux/modules/category";
import { getMenuCategoryList as getMenuCategoryListAction } from "../../redux/modules/category";

import { retrieveData, storeData } from "../../services/Storage";

import "../../styles/catalog.scss";

import { convertToList } from "../../utilities/storage";

function CatalogPage(props) {
  let { name } = useParams();

  const navigate = useNavigate();
  const [productActive, setProductActive] = useState(false);
  const [catName, setCatName] = useState(null);

  const [catalogListOnline, setCatalogListOnline] = useState([
    { loader: true },
    { loader: true },
    { loader: true },
    { loader: true },
  ]);

  const [homeCatalog, setHomeCatalog] = useState([]);

  const [filtersList, setFiltersList] = useState([]);
  const pricesList = [
    "Менее 2 000 ₽",
    "2 001 - 5 000 ₽",
    "5 001 - 10 000 ₽",
    "Более 10 000 ₽",
  ];

  async function _load(_name) {
    setFiltersList(
      retrieveData("menu_category") ? retrieveData("menu_category") : []
    );
    if (name) {
      const list = await props.getCategoryListByTitle(_name);
      if (list.catalog) setCatalogListOnline(convertToList(list.catalog));
      if (list.data) setCatName(list.data.title);
      const data = await props.getMenuCategoryList();
   
      let arr = data.map((item) => { 
        return {
          title: `${item.data.title} (${item.catalog.filter((i) => { return (i.is_active == 0) } ).length})`,
          title_url: `/catalog/${item.data.title_url}`,
        };
      });
      setFiltersList(arr);
      storeData("menu_category", arr);

    } else {
      const data = await props.getMenuCategoryList();
      setHomeCatalog(data);
      let arr = data.map((item) => {
        return {
          title: `${item.data.title} (${item.catalog.filter((i) => { return (i.is_active == 0) } ).length})`,
          title_url: `/catalog/${item.data.title_url}`,
        };
      });
      setFiltersList(arr);
      storeData("menu_category", arr);
      setCatalogListOnline([]);
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    _load(name);
  }, [name]);

  let homeList = homeCatalog.map((item, index) => {
    if (item.catalog.length > 0) {
      return (
        <UiCatalog
          key={index}
          title={item.data.title}
          category={item.data.title_url}
          subtitle=""
          list={convertToList(item.catalog)}
          minimized
        />
      );
    }
  });

  return (
    <PageContainer
      page="catalog"
 
      pageTitle={ ` ${catName ? catName : "Каталог цветов AmourFlora"} купить в Владимире с доставкой | AmourFlora `}  
      pageDescription={ `${catName ? catName : "Каталог цветов AmourFlora"} купить в студии декора и флористики ❀ AmourFlora ❀ с доставкой по Владимиру ➤ Доступные цены и огромный каталог букетов`}  
 

      pageKeywords=""
    >
      <UiCatalogBanner title={"Каталог букетов"} />
      <Container>
        <UiCatalogFilter filtersList={filtersList} pricesList={pricesList} />
        <div className="catalog-list-wrap">
          {homeList.length > 0 ? (
            homeList
          ) : (
            <UiCatalog
              title={catName}
              subtitle="Букеты готовые на доставку прямо сейчас. Быстрая доставка в течении часа."
              list={catalogListOnline}
            />
          )}
        </div>
      </Container>
    </PageContainer>
  );
}

export default connect(({ category, storage }) => ({ category, storage }), {
  getCategoryListByTitle: getCategoryListByTitleAction,
  getMenuCategoryList: getMenuCategoryListAction,
})(CatalogPage);
