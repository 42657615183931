import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import demo1 from "../../assets/demo/demo1.jpg";
import demo2 from "../../assets/demo/bouquet8.jpg";
import demo3 from "../../assets/demo/bouquet9.jpg";
import demo4 from "../../assets/demo/bouquet10.jpg";
import demo5 from "../../assets/demo/bouquet11.jpg";
import demo6 from "../../assets/demo/bouquet7.jpg";

import pageBg from "../../assets/banners/title-catalog.jpg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiCatalogFilter from "../../components/catalog/UiCatalogFilter";
import UiPageTitle from "../../components/header/UiPageTitle";

import { getStorageListByCat as getStorageListByCatAction } from "../../redux/modules/storage";

import "../../styles/catalog.scss";

function SpecialPage(props) {
  const catalogList = [
    {
      image: demo1,
      title: "Букет №1",
      text: "Описание или состав букета",
      price: "4 500",
      status: null,
    },
    {
      image: demo2,
      title: "Букет №2",
      text: "Описание или состав букета",
      price: "3 500",
      status: null,
    },
    {
      image: demo3,
      title: "Букет №3",
      text: "Описание или состав букета",
      price: "6 000",
      status: null,
    },
    {
      image: demo4,
      title: "Букет №4",
      text: "Описание или состав букета",
      price: "2 500",
      status: null,
    },
    {
      image: demo5,
      title: "Букет №5",
      text: "Описание или состав букета",
      price: "10 000",
      status: null,
    },
    {
      image: demo6,
      title: "Букет №6",
      text: "Описание или состав букета",
      price: "5 250",
      status: null,
    },
    {
      image: demo1,
      title: "Букет №1",
      text: "Описание или состав букета",
      price: "4 500",
      status: null,
    },
    {
      image: demo2,
      title: "Букет №2",
      text: "Описание или состав букета",
      price: "3 500",
      status: null,
    },
    {
      image: demo3,
      title: "Букет №3",
      text: "Описание или состав букета",
      price: "6 000",
      status: null,
    },
    {
      image: demo4,
      title: "Букет №4",
      text: "Описание или состав букета",
      price: "2 500",
      status: null,
    },
    {
      image: demo5,
      title: "Букет №5",
      text: "Описание или состав букета",
      price: "10 000",
      status: null,
    },
    {
      image: demo6,
      title: "Букет №6",
      text: "Описание или состав букета",
      price: "5 250",
      status: null,
    },
  ];

  const filtersList = [
    "Онлайн витрина (8)",
    "Авторские букеты (10)",
    "Цветочные композиции (6)",
    "Коллекция Simple (4)",
    "Моно, дуо и трио букеты (12)",
    "Коллекция сезонности (10)",
    "Свадебные букеты (12)",
    "Новинки (5)",
  ];
  const pricesList = [
    "Менее 2 000 ₽",
    "2 001 - 5 000 ₽",
    "5 001 - 10 000 ₽",
    "Более 10 000 ₽",
  ];

  return (
    <PageContainer
      page="special"
      pageTitle=""
      pageDescription=""
      pageKeywords=""
    >
      <UiPageTitle
        image={pageBg}
        linksList={[{ label: "День матери" }]}
        pageName="Букеты к празднику"
      />
      <Container>
        <UiCatalogFilter pricesList={pricesList} />
        <div className="catalog-list-wrap">
          <UiCatalog
            icon="mom"
            title="Букеты ко Дню Матери"
            subtitle="Прекрасные композиции для самой важной женщины в жизни каждого."
            list={catalogList}
          />
        </div>
      </Container>
    </PageContainer>
  );
}

export default SpecialPage;
