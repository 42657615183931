import React, { useState, useEffect } from "react";

import UiBtnColor from "../buttons/UiBtnColor";
import UiBtnIcon from "../buttons/UiBtnIcon";
import UiCheckList from "../forms/UiCheckList";
import UiModal from "./UiModal";
import UiSelectDate from "../forms/UiSelectDate";
import UiSelectOption from "../forms/UiSelectOption";

import OrderConstant from "../../constants/OrderConstant";

import {  toArrayList } from "../../utilities/array";
import { excludeOldTime } from "../../utilities/date";

import "./UiOrderAddress.scss";

const UiOrderTime = (props) => {
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [warnT, setWarnT] = useState(false);

  const [deliveryTimeType, setDeliveryTimeType] = useState(0);
  const [deliveryInterval, setDeliveryInterval] = useState(
    excludeOldTime(new Date(), OrderConstant.ORDER_TIME_TYPE )
  );

  useEffect(() => {
     
      setDeliveryInterval( excludeOldTime(date, OrderConstant.ORDER_TIME_TYPE ) );
   
  }, [date ]);

  return (
    <UiModal show={props.modalOpen}>
      <div className="order-address">
        <h2>Время доставки</h2>
        <div className="modal-close-btn">
          <UiBtnIcon
            color="black"
            fill="white"
            icon="close"
            iconSize={12}
            onClick={() => props.modalClose()}
            size={40}
          />
        </div>
        <UiCheckList
          colored={true}
          checkList={[
            { option: "Как можно быстрее" },
            { option: "Ко времени" },
            { option: "Узнать у получателя" },
          ]}
          onChange={(val) => {
            setDeliveryTimeType(val);
            if(val != 2){
              setTime( excludeOldTime(date, OrderConstant.ORDER_TIME_TYPE )[0]);
            } else {
              setTime("");
            }
          }}
          selected={deliveryTimeType}
        />
        {deliveryTimeType === 1 && (
          <div className="order-time-grid">
            <UiSelectDate
              label="Дата доставки"
              value={date}
              onChange={(e) => {
                setDate(e);
              }}
            />
            <UiSelectOption
              label="Время доставки"
              optionList={toArrayList(deliveryInterval)}
       
              onChange={(e) => {
                setTime(excludeOldTime(date, OrderConstant.ORDER_TIME_TYPE )[e]);
                setWarnT(excludeOldTime(date, OrderConstant.ORDER_TIME_TYPE )[e] == null);
              }}
              warnText={warnT && "Введите Время доставки"}
            />
          </div>
        )}

        {deliveryTimeType === 2 && (
          <div className="order-time-grid">
            <UiSelectDate
              label="Дата звонка"
              value={date}
              onChange={(e) => {
                setDate(e);
              }}
            />
          </div>
        )}

        <div className="order-time-button">
          <UiBtnColor 
            color="black" 
            text="Сохранить" 
            onClick={() => {
              props.onChange(date,time,deliveryTimeType);
              props.modalClose();
            }}
          />
        </div>
      </div>
    </UiModal>
  );
};
export default UiOrderTime;
