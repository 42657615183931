import React from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./routes/ProtectedRoute";

import HomePage from "../pages/home/HomePage";

import CatalogPage from "../pages/catalog/CatalogPage";
import SpecialPage from "../pages/catalog/SpecialPage"
import ProductPage from "../pages/product/ProductPage";
import SubscribePage from "../pages/subscribe/SubscribePage";

import LoginPage from "../pages/login/LoginPage";
import ProfilePage from "../pages/profile/ProfilePage";

import OrderPage from "../pages/order/OrderPage";
import OrderSuccessPage from "../pages/order/OrderSuccessPage";

import DeliveryPage from "../pages/static/DeliveryPage";
import ContactsPage from "../pages/static/ContactsPage";
import PolicyPage from "../pages/static/PolicyPage";

import NotFoundPage from "../pages/notfound/NotFoundPage";

function AppNavigator() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
 
      <Route path="/catalog" element={<CatalogPage />} />
      <Route path="/catalog/:name" element={<CatalogPage />} />

      <Route path="/special" element={<SpecialPage />} />

      <Route path="/product/:title" element={<ProductPage />} />

      <Route path="/subscribe" element={<SubscribePage />} />

      <Route path="/login" element={<LoginPage />} />

      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order"
        element={
          <ProtectedRoute>
            <OrderPage />
          </ProtectedRoute>
        }
      />

      <Route path="/order/success" element={<OrderSuccessPage />} />

      <Route path="/delivery" element={<DeliveryPage />} />
      <Route path="/contacts" element={<ContactsPage />} />
      <Route path="/policy" element={<PolicyPage />} />

      <Route path="*" element={<NotFoundPage />}  status={404} />
    </Routes>
  );
}

export default AppNavigator;
