import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SvgLeafs } from "../../assets/logo/leafs.svg";

import UiBtnColor from "../buttons/UiBtnColor";

import "./UiSectionTitle.scss";

const UiSectionTitle = (props) => {
  let navigate = useNavigate();

  return (
    <div className={"section-title" + (props.align ? " " + props.align : "")}>
      {props.align ? null : <SvgLeafs className="section-title-icon" />}
      <h3>{props.title}</h3>
      {props.text && <p>{props.text}</p>}
      {props.buttonText ? (
        <UiBtnColor
          color="brand"
          onClick={props.buttonClick}
          text={props.buttonText}
        />
      ) : null}
    </div>
  );
};
export default UiSectionTitle;
