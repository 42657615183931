import React from "react";

import pageBg from "../../assets/banners/title-contact.jpg";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBtnIcon from "../../components/buttons/UiBtnIcon";
import UiIcon from "../../components/icon/UiIcon";
import UiPageTitle from "../../components/header/UiPageTitle";

import "./Static.scss";

function ContactsPage() {
  return (
    <PageContainer page="contacts" pageTitle="">
      <UiPageTitle image={pageBg} large={true} linksList={[]} pageName="" />
      <Container>
        <div className="contacts-wrap">
          <div className="contacts-info-list">
            <h3>Информация о магазинах:</h3>
            <div className="contacts-info">
              <UiIcon icon="pin" fill="brand" size="16" />
              <span>г. Владимир, ул. Усти-на-лабе, 21</span>
            </div>
            <a className="contacts-info" href="tel:+79308301501">
              <UiIcon icon="call" fill="brand" size="16" />
              <span>+7 930 830 15 01</span>
            </a>
            <a className="contacts-info" href="mailto:info@amourflora.ru">
              <UiIcon icon="message" fill="brand" size="16" />
              <span>info@amourflora.ru</span>
            </a>
            <div className="contacts-info">
              <UiIcon icon="clock" fill="brand" size="16" />
              <span>Время работы: 8:00-22:00</span>
            </div>
            <div className="contacts-info">
              <UiIcon icon="clock2" fill="brand" size="16" />
              <span>Время доставки: круглосуточно</span>
            </div>
            <h3>Информация о юридическом лице:</h3>
            <div className="contacts-info">
              <UiIcon icon="user2" fill="brand" size="16" />
              <span>ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ КОЗЛОВ МАКСИМ ПАВЛОВИЧ</span>
            </div>
            <div className="contacts-info">
              <UiIcon icon="clipboard" fill="brand" size="16" />
              <span>ИНН 332912700674</span>
            </div>
            <div className="contacts-info">
              <UiIcon icon="pin" fill="brand" size="16" />
              <span>
                Юридический адрес: Владимирская обл., г.о. город Владимир, г.
                Владимир
              </span>
            </div>
          </div>
          <div className="contacts-info-list">
            <h3>ПРИСОЕДИНЯЙТЕСЬ К НАМ В СОЦ. СЕТЯХ</h3>
            <div className="contacts-info">
              <UiBtnIcon
                color="black"
                icon="vk"
                iconSize="24"
                fill="white"
                href="https://vk.com/amourflora"
                size="36"
                style={{ marginRight: 16 }}
                target="_blank"
              />
              <UiBtnIcon
                color="black"
                icon="instagram"
                iconSize="24"
                fill="white"
                href="https://instagram.com/amourflora?igshid=MzRlODBiNWFlZA=="
                size="36"
                target="_blank"
              />
            </div>
            <h3>Наши магазины</h3>
            <div className="contacts-map">
              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A78085e8a35e3ee51f002f79d1ffbcdb53127f761716d77d9d9641143d0c3db3a&amp;source=constructor"
                width="100%"
                height="400"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default ContactsPage;
