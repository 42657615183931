import React, { useEffect, useState } from "react";

import { ReactComponent as SvgCheck } from "../../assets/ui/checked.svg";

import "./UiCheckList.scss";

const UiCheck = (props) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(props.value);
  }, []);

  const setCheck = () => {
    let new_val = !active;
    setActive(new_val);
    props.onChange(new_val);
  };

  return (
    <button
      className="check-item"
      disabled={props.disabled}
      onClick={() => setCheck()}
    >
      <div className={"check-item-btn" + (active ? " active" : "")}>
        {active ? <SvgCheck width={20} height={20} fill="#373e4e" /> : null}
      </div>
      <p>
        {props.label}
        <span> {props.labelMark}</span>
      </p>
    </button>
  );
};
export default UiCheck;
