import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  changeLoaderState as changeLoaderStateAction
} from "../../redux/modules/login" 

import UiLoader from "../../components/modals/UiLoader";
import UiAlert from "../../components/modals/UiAlert";

function AppModalComponent(props) {
 
    return (
      <div className="modal-wrap">
        <UiLoader modalOpen={props?.login?.loaderShowed} />
        <UiAlert modalOpen={props?.login?.alertShowed} title="Заголовок сообщения" text={["Текст всплывающего сообщения"]} />
      </div>
    )

  
  
}

export default connect(
  ({ login }) => ({ login }),
  {
    changeLoaderState: changeLoaderStateAction
  }
)(AppModalComponent);

