import React from "react";
import UiBarNum from "../bars/UiBarNum";
import UiBtnIcon from "../buttons/UiBtnIcon";

import "./UiBasketCard.scss";

const UiBasketCard = (props) => {
  return (
    <div className="basket-card">
      <div className="basket-card-wrap">
        <img className="basket-card-image" src={props.image} />
        <div className="basket-card-info">
          <h3>{props.title}</h3>
          <UiBarNum
            num={props.num}
            small={true}
            onMinus={props.onMinus}
            onPlus={props.onPlus}
          />
        </div>
        <div className="basket-card-control">
          <p>
            <strong>{props.price} ₽</strong>
          </p>
        </div>
      </div>
      <div className="basket-card-button">
        <UiBtnIcon
          icon="close"
          iconSize={8}
          size="24"
          onClick={props.onClick}
        />
      </div>
    </div>
  );
};
export default UiBasketCard;
