import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as SvgCheck } from "../../assets/alert/check.svg";

import {
  getStorageListByCat as getStorageListByCatAction,
  getStorageItem as getStorageItemAction,
} from "../../redux/modules/storage";
import {
  checkInUserBasket as checkInUserBasketAction,
  addUserBasket as addUserBasketAction,
  getStoredUserBasket as getStoredUserBasketAction,
  changeUserBasket as changeUserBasketAction,
} from "../../redux/modules/login";
import {
  getSettingList as getSettingListAction,
  getSettingValue as getSettingValueAction,
} from "../../redux/modules/setting";

import Container from "../../components/container/Container";
import PageContainer from "../../components/container/PageContainer";
import UiBtnColor from "../../components/buttons/UiBtnColor";
import UiPageTitle from "../../components/header/UiPageTitle";

import pageBg from "../../assets/banners/title-delivery.jpg";

import "./Order.scss";

function OrderSuccessPage(props) {
  let navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [freeDeliveryPrice, setFDP] = useState(3500);

  useEffect(() => {
    if (props.login.user) setUser(true);
  }, [props.login.user]);

  useEffect(() => {
    props.getStoredUserBasket();
    props.getSettingList();
  }, []);

  useEffect(() => {
    setFDP(props.getSettingValue("sum_busket_free_delivery"));
  }, [props.setting.list]);

  return (
    <PageContainer 
      page="order"
      pageTitle=""
    >
      <UiPageTitle
         image={pageBg}
        linksList={[{ href: "/order", label: "Корзина" }]}
        pageName="Оформление заказа"
      />
      <Container>
        <div className="order-success">
          <SvgCheck className="order-success-icon" />
          <h3>Заказ успешно создан</h3>
          <p>Ожидайте уведомления от нашего менеджера</p>
          <UiBtnColor
            color="brand"
            onClick={() => navigate("/profile")}
            text="Профиль"
          />
        </div>
      </Container>
    </PageContainer>
  );
}

export default connect(
  ({ storage, login, setting }) => ({ storage, login, setting }),
  {
    getStorageListByCat: getStorageListByCatAction,
    getStorageItem: getStorageItemAction,

    checkInUserBasket: checkInUserBasketAction,
    addUserBasket: addUserBasketAction,
    getStoredUserBasket: getStoredUserBasketAction,
    changeUserBasket: changeUserBasketAction,
    getSettingList: getSettingListAction,
    getSettingValue: getSettingValueAction,
  }
)(OrderSuccessPage);
